import React, { useEffect, useState } from "react";
import { getFavoritesByUserId } from "../admin/Categories/services/favoritesServices";
import ProductsRow from "../Components/ProductsRow";
const FavoritesList = ({ currentUser, isLoggedIn, favorites }) => {
  console.log(currentUser, "currentUser");

  return (
    <>
      <div className="container">
        <ProductsRow from={"favorites"} products={favorites} />
      </div>
    </>
  );
};

export default FavoritesList;
