import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import "../css/admin-css/Products.css";
import "react-quill/dist/quill.snow.css";
import { getAllUsers } from "./adminServices/adminServices";
const Customers = () => {
  const [customers, setCustomers] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedAddresses, setSelectedAddresses] = useState([]);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await getAllUsers();
      console.log(response, "response");

      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error.message || error);
    }
  };

  useEffect(() => {
    console.log(customers, "customers");
  }, customers);

  const handleOpenAddressesModal = (addresses) => {
    setSelectedAddresses(addresses);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAddresses([]);
  };

  return (
    <div className="container-fluid mt-1">
      {/* <h6 className="mb-4">Products for Category ID: {id}</h6> */}
      <div className="product-header">
        <h6 className="mb-4">Customers</h6>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-lg-12">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Addresses</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customers.map((customer) => (
                      <TableRow key={customer._id}>
                        <TableCell>{customer.firstName}</TableCell>
                        <TableCell>{customer.lastName}</TableCell>
                        <TableCell>{customer.email}</TableCell>
                        <TableCell>{customer.phoneNumber}</TableCell>
                        <TableCell>
                          {customer.addresses.length > 0 ? (
                            <IconButton
                              onClick={() =>
                                handleOpenAddressesModal(customer.addresses)
                              }
                            >
                              <HomeIcon />
                            </IconButton>
                          ) : (
                            "No addresses"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Addresses Modal */}
              <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle>Customer Addresses</DialogTitle>
                <DialogContent dividers>
                  {selectedAddresses.length > 0 ? (
                    selectedAddresses.map((address, index) => (
                      <div key={index} style={{ marginBottom: "10px" }}>
                        <strong>Full Name: </strong>
                        {address.fullName}
                        <br />
                        <strong>Primary Phone: </strong>
                        {address.primaryPhone}
                        <br />
                        <strong>Address: </strong>
                        {address.address}
                        <br />
                        <strong>Landmark: </strong>
                        {address.landmark}
                        <br />
                        <strong>Pincode: </strong>
                        {address.pincode}
                        <br />
                        <strong>City: </strong>
                        {address.city}
                        <br />
                        <strong>State: </strong>
                        {address.state}
                        <br />
                        <hr />
                      </div>
                    ))
                  ) : (
                    <p>No addresses available</p>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
