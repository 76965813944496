// src/components/ProductView.js
import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../css/LandingPage.css";

const MainFooter = () => {
  const navigate = useNavigate();

  const paymentIcon = require("../assets/images/payment.png");
  // Define your social media URLs
  const SOCIAL_MEDIA_LINKS = {
    youtube: "https://youtube.com/@globalindiashop?si=C_XDNLErd_1dksuu", // Replace with your YouTube channel URL
    facebook: "https://www.facebook.com/your-page", // Replace with your Facebook page URL
    twitter: "https://x.com/GlobalIndiaShop", // Replace with your Twitter profile URL
    instagram:
      "https://www.instagram.com/global_india_shop?igsh=MTE0N3ExYjJ5NXAzMA==", // Replace with your Instagram profile URL
  };

  const goTo = (event) => {
    if (event == "about") {
      navigate(`/about-global-india-shop`);
    } else if (event == "contact") {
      navigate(`/contact-us`);
    } else if (event == "terms") {
      navigate(`/terms-conditions`);
    }
  };

  return (
    <>
      <footer>
        {/* <div className="footer-category">
          <div className="container">
            <h2 className="footer-category-title">Brand directory</h2>

            <div className="footer-category-box">
              <h3 className="category-box-title">Fashion :</h3>

              <a href="#" className="footer-category-link">
                T-shirt
              </a>
              <a href="#" className="footer-category-link">
                Shirts
              </a>
              <a href="#" className="footer-category-link">
                shorts & jeans
              </a>
              <a href="#" className="footer-category-link">
                jacket
              </a>
              <a href="#" className="footer-category-link">
                dress & frock
              </a>
              <a href="#" className="footer-category-link">
                innerwear
              </a>
              <a href="#" className="footer-category-link">
                hosiery
              </a>
            </div>

            <div className="footer-category-box">
              <h3 className="category-box-title">footwear :</h3>

              <a href="#" className="footer-category-link">
                sport
              </a>
              <a href="#" className="footer-category-link">
                formal
              </a>
              <a href="#" className="footer-category-link">
                Boots
              </a>
              <a href="#" className="footer-category-link">
                casual
              </a>
              <a href="#" className="footer-category-link">
                cowboy shoes
              </a>
              <a href="#" className="footer-category-link">
                safety shoes
              </a>
              <a href="#" className="footer-category-link">
                Party wear shoes
              </a>
              <a href="#" className="footer-category-link">
                Branded
              </a>
              <a href="#" className="footer-category-link">
                Firstcopy
              </a>
              <a href="#" className="footer-category-link">
                Long shoes
              </a>
            </div>

            <div className="footer-category-box">
              <h3 className="category-box-title">jewellery :</h3>

              <a href="#" className="footer-category-link">
                Necklace
              </a>
              <a href="#" className="footer-category-link">
                Earrings
              </a>
              <a href="#" className="footer-category-link">
                Couple rings
              </a>
              <a href="#" className="footer-category-link">
                Pendants
              </a>
              <a href="#" className="footer-category-link">
                Crystal
              </a>
              <a href="#" className="footer-category-link">
                Bangles
              </a>
              <a href="#" className="footer-category-link">
                bracelets
              </a>
              <a href="#" className="footer-category-link">
                nosepin
              </a>
              <a href="#" className="footer-category-link">
                chain
              </a>
              <a href="#" className="footer-category-link">
                Earrings
              </a>
              <a href="#" className="footer-category-link">
                Couple rings
              </a>
            </div>

            <div className="footer-category-box">
              <h3 className="category-box-title">cosmetics :</h3>

              <a href="#" className="footer-category-link">
                Shampoo
              </a>
              <a href="#" className="footer-category-link">
                Bodywash
              </a>
              <a href="#" className="footer-category-link">
                Facewash
              </a>
              <a href="#" className="footer-category-link">
                makeup kit
              </a>
              <a href="#" className="footer-category-link">
                liner
              </a>
              <a href="#" className="footer-category-link">
                lipstick
              </a>
              <a href="#" className="footer-category-link">
                prefume
              </a>
              <a href="#" className="footer-category-link">
                Body soap
              </a>
              <a href="#" className="footer-category-link">
                scrub
              </a>
              <a href="#" className="footer-category-link">
                hair gel
              </a>
              <a href="#" className="footer-category-link">
                hair colors
              </a>
              <a href="#" className="footer-category-link">
                hair dye
              </a>
              <a href="#" className="footer-category-link">
                sunscreen
              </a>
              <a href="#" className="footer-category-link">
                skin loson
              </a>
              <a href="#" className="footer-category-link">
                liner
              </a>
              <a href="#" className="footer-category-link">
                lipstick
              </a>
            </div>
          </div>
        </div> */}

        <div className="footer-nav">
          <div className="container">
            {/* <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">Popular Categories</h2>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Fashion
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Electronic
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Cosmetic
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Health
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Watches
                </a>
              </li>
            </ul>

            <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">Products</h2>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Prices drop
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  New products
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Best sales
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Contact us
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Sitemap
                </a>
              </li>
            </ul>

            <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">Our Company</h2>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Delivery
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Legal Notice
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Terms and conditions
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  About us
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Secure payment
                </a>
              </li>
            </ul>

            <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">Services</h2>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Prices drop
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  New products
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Best sales
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Contact us
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Sitemap
                </a>
              </li>
            </ul> */}

            <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">Contact</h2>
              </li>

              <li className="footer-nav-item flex">
                <div className="icon-box">
                  <ion-icon
                    className="footer-icons"
                    name="storefront-outline"
                  ></ion-icon>
                </div>
                <a onClick={() => goTo("about")} className="footer-nav-link">
                  About Us
                </a>
              </li>

              <li className="footer-nav-item flex">
                <div className="icon-box">
                  <ion-icon name="call"></ion-icon>
                </div>
                <a onClick={() => goTo("contact")} className="footer-nav-link">
                  Contact Us
                </a>
              </li>

              <li className="footer-nav-item flex">
                <div className="icon-box">
                  <ion-icon name="document-text"></ion-icon>
                </div>
                <a onClick={() => goTo("terms")} className="footer-nav-link">
                  Terms & Conditions
                </a>
              </li>

              <li className="footer-nav-item flex">
                <div className="icon-box">
                  <ion-icon name="location-outline"></ion-icon>
                </div>
                <address className="content">
                  120D, St Marys Street, MaravanKudirupu, TamilNadu, India
                </address>
              </li>

              <li className="footer-nav-item flex">
                <div className="icon-box">
                  <ion-icon name="call-outline"></ion-icon>
                </div>

                <a href="tel:+607936-8058" className="footer-nav-link">
                  (+91) 79041 39207
                </a>
              </li>

              <li className="footer-nav-item flex">
                <div className="icon-box">
                  <ion-icon name="mail-outline"></ion-icon>
                </div>
                <a
                  href="mailto:contact.globalindiashop@gmail.com"
                  className="footer-nav-link "
                >
                  contact.globalindiashop@gmail.com
                </a>
              </li>
            </ul>

            <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">
                  {" "}
                  <p className="follow-us-main-footer">
                    Follow us on social medias for updates on our upcoming
                    products with <span className="offer">60%</span> offer
                  </p>
                </h2>
              </li>

              <li>
                <ul className="social-link">
                  <li className="footer-nav-item">
                    <a
                      href={SOCIAL_MEDIA_LINKS.youtube}
                      className="footer-nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ion-icon name="logo-youtube"></ion-icon>
                    </a>
                  </li>
                  {/* <li className="footer-nav-item">
                    <a
                      href={SOCIAL_MEDIA_LINKS.facebook}
                      className="footer-nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ion-icon name="logo-facebook"></ion-icon>
                    </a>
                  </li> */}
                  <li className="footer-nav-item">
                    <a
                      href={SOCIAL_MEDIA_LINKS.twitter}
                      className="footer-nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ion-icon name="logo-twitter"></ion-icon>
                    </a>
                  </li>
                  <li className="footer-nav-item">
                    <a
                      href={SOCIAL_MEDIA_LINKS.instagram}
                      className="footer-nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ion-icon name="logo-instagram"></ion-icon>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <img
              src={paymentIcon}
              alt="payment method"
              className="payment-img"
            />

            <p className="copyright">
              Copyright &copy; <a href="#">Global India Shop</a> all rights
              reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default MainFooter;
