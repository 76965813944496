import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton, // Use IconButton for the reset icon
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import styles for date picker
import { getAllOrders, updateOrder } from "./adminServices/adminServices";
import RestoreIcon from "@mui/icons-material/Restore"; // Import the reset icon
import "../css/admin-css/OrderDetailsPage.css"; // Import the CSS file
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [exactDate, setExactDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    filterOrders();
  }, [exactDate, startDate, endDate, fullName, phoneNumber, orders]);

  const fetchOrders = async () => {
    try {
      const response = await getAllOrders();
      setOrders(response.data);
      setFilteredOrders(response.data); // Initialize filtered orders
    } catch (error) {
      console.error("Error fetching orders:", error.message || error);
    }
  };

  const filterOrders = () => {
    let updatedOrders = [...orders];

    // Filter by exact date
    if (exactDate) {
      updatedOrders = updatedOrders.filter(
        (order) =>
          new Date(order.orderDate).toDateString() === exactDate.toDateString()
      );
    }

    // Filter by date range
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59); // Set end time to the end of the day

      updatedOrders = updatedOrders.filter((order) => {
        const orderDate = new Date(order.orderDate);
        return orderDate >= start && orderDate <= end;
      });
    }

    // Filter by full name
    if (fullName) {
      updatedOrders = updatedOrders.filter((order) =>
        order.customerAddress.fullName
          .toLowerCase()
          .includes(fullName.toLowerCase())
      );
    }

    // Filter by phone number
    if (phoneNumber) {
      updatedOrders = updatedOrders.filter((order) =>
        order.customerAddress.primaryPhone.includes(phoneNumber)
      );
    }

    setFilteredOrders(updatedOrders);
  };

  // Reset function to clear all filters
  const resetFilters = () => {
    setExactDate(null);
    setStartDate(null);
    setEndDate(null);
    setFullName("");
    setPhoneNumber("");
    setFilteredOrders(orders); // Reset to show all orders
  };

  const handleRowClick = (order) => {
    navigate("/admin-dashboard/view-order", {
      state: { order },
    });
  };

  const orderStatuses = [
    "Order Placed",
    "Order Confirmed",
    "Processing",
    "Shipped/Dispatched",
    "Out for Delivery",
    "Delivered",
  ];

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const updatedOrder = await updateOrder(orderId, newStatus);
      console.log("Order updated successfully:", updatedOrder);
      fetchOrders();
      toast.success(`${newStatus} updated successfully`, {
        position: "top-center",
        autoClose: 1000,
      });
      // Optionally, refresh the order list or update local state here
    } catch (error) {
      console.error("Failed to update order status:", error);
    }
  };

  return (
    <div className="container-fluid mt-1">
      <div className="product-header">
        <h6 className="mb-4">Orders</h6>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="order-filters">
            <div className="filter-wrapper">
              <label className="filter-labels">Exact Date:</label>
              <DatePicker
                selected={exactDate}
                onChange={(date) => setExactDate(date)}
                dateFormat="yyyy-MM-dd"
                className="filter-input form-control"
                placeholderText="Select exact date" // Placeholder for the date picker
              />
            </div>

            <div className="filter-wrapper">
              <label className="filter-labels">Start Date:</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                className="filter-input form-control"
                placeholderText="Select start date" // Placeholder for the date picker
              />
            </div>

            <div className="filter-wrapper">
              <label className="filter-labels">End Date:</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                className="filter-input form-control"
                placeholderText="Select end date" // Placeholder for the date picker
              />
            </div>

            <div className="filter-wrapper">
              <label className="filter-labels">Full Name:</label>

              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            <div className="filter-wrapper">
              <label className="filter-labels">Phone Number:</label>
              <input
                type="number"
                className="form-control"
                placeholder="Phone Number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            {/* Reset Icon */}
            <IconButton
              color="primary"
              onClick={resetFilters}
              style={{
                marginLeft: "1rem",
                borderRadius: "50%",
                height: "40px",
                width: "40px",
              }}
            >
              <RestoreIcon />
            </IconButton>
          </div>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Total Price</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders.map((order) => (
                  <TableRow key={order._id} style={{ cursor: "pointer" }}>
                    <TableCell>
                      {new Date(order.orderDate)
                        .toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })
                        .replace(/ /g, "-")}
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(order)}>
                      {order.customerAddress.fullName}
                    </TableCell>
                    <TableCell>{order.customerAddress.primaryPhone}</TableCell>
                    <TableCell>{order.customerAddress.state}</TableCell>
                    <TableCell>{order.finalCart.totalPrice}</TableCell>
                    <TableCell>
                      <select
                        className="form-select status-select"
                        value={order.orderStatus} // Assuming `orderStatus` is part of `finalCart`
                        onChange={(e) =>
                          handleStatusChange(order._id, e.target.value)
                        }
                      >
                        {orderStatuses.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Orders;
