// src/components/ProductView.js
import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../css/LandingPage.css";
import { Collapse } from "@mui/material";

const Footer = ({ from, cartObj, finalCartObj, favorites }) => {
  useEffect(() => {
    // Mobile menu variables
    const mobileMenuOpenBtn = document.querySelectorAll(
      "[data-mobile-menu-open-btn]"
    );
    const mobileMenu = document.querySelectorAll("[data-mobile-menu]");
    const mobileMenuCloseBtn = document.querySelectorAll(
      "[data-mobile-menu-close-btn]"
    );
    const mobileLinkCloseBtn = document.querySelectorAll(
      "[data-mobile-link-close-btn]"
    );
    const mobileAboutCloseBtn = document.querySelectorAll(
      "[data-mobile-about-close-btn]"
    );
    const mobileContactCloseBtn = document.querySelectorAll(
      "[data-mobile-contact-close-btn]"
    );
    const mobileTermsCloseBtn = document.querySelectorAll(
      "[data-mobile-terms-close-btn]"
    );
    const overlay = document.querySelector("[data-overlay]");
    for (let i = 0; i < mobileMenuOpenBtn.length; i++) {
      // Mobile menu function
      const mobileMenuCloseFunc = () => {
        if (mobileMenu[i]) mobileMenu[i].classList.remove("active");
        if (overlay) overlay.classList.remove("active");
      };

      if (mobileMenuOpenBtn[i]) {
        mobileMenuOpenBtn[i].addEventListener("click", () => {
          if (mobileMenu[i]) mobileMenu[i].classList.add("active");
          if (overlay) overlay.classList.add("active");
        });
      }

      if (mobileMenuCloseBtn[i]) {
        mobileMenuCloseBtn[i].addEventListener("click", mobileMenuCloseFunc);
      }
      if (mobileLinkCloseBtn[i]) {
        mobileLinkCloseBtn[i].addEventListener("click", mobileMenuCloseFunc);
      }
      if (mobileAboutCloseBtn[i]) {
        mobileAboutCloseBtn[i].addEventListener("click", mobileMenuCloseFunc);
      }
      if (mobileContactCloseBtn[i]) {
        mobileContactCloseBtn[i].addEventListener("click", mobileMenuCloseFunc);
      }
      if (mobileTermsCloseBtn[i]) {
        mobileTermsCloseBtn[i].addEventListener("click", mobileMenuCloseFunc);
      }
      if (overlay) {
        overlay.addEventListener("click", mobileMenuCloseFunc);
      }
    }
    // Cleanup function to remove event listeners
    return () => {
      // Mobile menu event listeners
      mobileMenuOpenBtn.forEach((btn, i) => {
        if (btn)
          btn.removeEventListener("click", () => {
            if (mobileMenu[i]) mobileMenu[i].classList.add("active");
            if (overlay) overlay.classList.add("active");
          });
      });
      mobileMenuCloseBtn.forEach((btn, i) => {
        if (btn)
          btn.removeEventListener("click", () => {
            if (mobileMenu[i]) mobileMenu[i].classList.remove("active");
            if (overlay) overlay.classList.remove("active");
          });
      });
      mobileLinkCloseBtn.forEach((btn, i) => {
        if (btn)
          btn.removeEventListener("click", () => {
            if (mobileMenu[i]) mobileMenu[i].classList.remove("active");
            if (overlay) overlay.classList.remove("active");
          });
      });
      mobileAboutCloseBtn.forEach((btn, i) => {
        if (btn)
          btn.removeEventListener("click", () => {
            if (mobileMenu[i]) mobileMenu[i].classList.remove("active");
            if (overlay) overlay.classList.remove("active");
          });
      });
      mobileContactCloseBtn.forEach((btn, i) => {
        if (btn)
          btn.removeEventListener("click", () => {
            if (mobileMenu[i]) mobileMenu[i].classList.remove("active");
            if (overlay) overlay.classList.remove("active");
          });
      });
      mobileTermsCloseBtn.forEach((btn, i) => {
        if (btn)
          btn.removeEventListener("click", () => {
            if (mobileMenu[i]) mobileMenu[i].classList.remove("active");
            if (overlay) overlay.classList.remove("active");
          });
      });
      if (overlay)
        overlay.removeEventListener("click", () => {
          mobileMenu.forEach((menu) => menu.classList.remove("active"));
          if (overlay) overlay.classList.remove("active");
        });
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const myLogo = require("../assets/images/icons/myLogo.png");

  const [mensOpen, setMensOpen] = useState(false);

  const handleMensClick = () => {
    setMensOpen(!mensOpen);
  };

  const [womensOpen, setWomensOpen] = useState(false);

  const handleWoensClick = () => {
    setWomensOpen(!womensOpen);
  };

  const [mobileJewelleryOpen, setMobileJewelleryOpen] = useState(false);

  const handleMobileJewellery = () => {
    setMobileJewelleryOpen(!mobileJewelleryOpen);
  };

  const [mobilePerfumeOpen, setMobilePerfumeOpen] = useState(false);

  const handleMobilePerfume = () => {
    setMobilePerfumeOpen(!mobilePerfumeOpen);
  };

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };
  const handleMessageClick = () => {
    navigate("/messages");
  };
  const handleCartClick = () => {
    navigate("/cart");
  };

  const SOCIAL_MEDIA_LINKS = {
    youtube: "https://youtube.com/@globalindiashop?si=C_XDNLErd_1dksuu", // Replace with your YouTube channel URL
    facebook: "https://www.facebook.com/your-page", // Replace with your Facebook page URL
    twitter: "https://x.com/GlobalIndiaShop", // Replace with your Twitter profile URL
    instagram:
      "https://www.instagram.com/global_india_shop?igsh=MTE0N3ExYjJ5NXAzMA==", // Replace with your Instagram profile URL
  };

  const goTo = (event) => {
    if (event == "about") {
      navigate(`/about-global-india-shop`);
    } else if (event == "contact") {
      navigate(`/contact-us`);
    } else if (event == "terms") {
      navigate(`/terms-conditions`);
    } else if (event == "home") {
      navigate(`/`);
    }
  };

  console.log(from, "from");
  console.log(cartObj, "cartObjHeader");

  const handleIcons = (event) => {
    if (event == "cart") {
      navigate("/checkout");
    } else if (event == "favorites") {
      navigate("/favorites");
    } else if (event == "profile") {
      navigate("/profile");
    }
  };

  return (
    <>
      <div className="mobile-bottom-navigation">
        <button className="action-btn" data-mobile-menu-open-btn>
          <ion-icon
            name="menu-outline"
            className="mobile-footer-icons"
          ></ion-icon>
        </button>

        <button
          onClick={() => {
            handleIcons("cart");
          }}
          className="action-btn"
        >
          <ion-icon
            className="mobile-footer-icons"
            name="bag-handle-outline"
          ></ion-icon>
          <span className="count">{cartObj?.length}</span>
        </button>

        <button className="action-btn" onClick={handleLogoClick}>
          <ion-icon
            className="mobile-footer-icons"
            name="home-outline"
          ></ion-icon>
        </button>

        <button
          className="action-btn"
          onClick={() => {
            handleIcons("favorites");
          }}
        >
          <ion-icon
            className="mobile-footer-icons"
            name="heart-outline"
          ></ion-icon>

          <span className="count">{favorites?.length}</span>
        </button>

        <button
          className="action-btn"
          data-mobile-menu-open-btn
          onClick={() => {
            handleIcons("profile");
          }}
        >
          <ion-icon
            className="mobile-footer-icons"
            name="person-outline"
          ></ion-icon>
        </button>
      </div>

      <nav className="mobile-navigation-menu has-scrollbar" data-mobile-menu>
        <div className="menu-top">
          <h2 className="menu-title">Menu</h2>

          <button className="menu-close-btn" data-mobile-menu-close-btn>
            <ion-icon
              className="mobile-footer-icons"
              name="close-outline"
            ></ion-icon>
          </button>
        </div>

        <ul className="mobile-menu-category-list">
          <li className="menu-category" data-mobile-link-close-btn>
            <a onClick={() => goTo("home")} className="menu-title">
              Home
            </a>
          </li>
          <li className="menu-category" data-mobile-about-close-btn>
            <a onClick={() => goTo("about")} className="menu-title">
              About Us
            </a>
          </li>

          {/* <li className="menu-category">
            <button
              className="accordion-menu"
              onClick={handleMensClick}
              data-accordion-btn
            >
              <p className="menu-title">Men's</p>
              <div>
                {mensOpen ? (
                  <ion-icon
                    name="remove-outline"
                    className="remove-icon"
                  ></ion-icon>
                ) : (
                  <ion-icon name="add-outline" className="add-icon"></ion-icon>
                )}
              </div>
            </button>

            <Collapse in={mensOpen}>
              {" "}
              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Shirt
                </a>
              </li>
              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Shorts & Jeans
                </a>
              </li>
              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Safety Shoes
                </a>
              </li>
              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Wallet
                </a>
              </li>
            </Collapse>
          </li>

          <li className="menu-category">
            <button
              className="accordion-menu"
              onClick={handleWoensClick}
              data-accordion-btn
            >
              <p className="menu-title">Women's</p>

              <div>
                {womensOpen ? (
                  <ion-icon
                    name="remove-outline"
                    className="remove-icon"
                  ></ion-icon>
                ) : (
                  <ion-icon name="add-outline" className="add-icon"></ion-icon>
                )}
              </div>
            </button>

            <Collapse in={womensOpen}>
              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Dress & Frock
                </a>
              </li>

              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Earrings
                </a>
              </li>

              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Necklace
                </a>
              </li>

              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Makeup Kit
                </a>
              </li>
            </Collapse>
          </li>

          <li className="menu-category">
            <button
              className="accordion-menu"
              onClick={handleMobileJewellery}
              data-accordion-btn
            >
              <p className="menu-title">Jewelry</p>

              <div>
                {mobileJewelleryOpen ? (
                  <ion-icon
                    name="remove-outline"
                    className="remove-icon"
                  ></ion-icon>
                ) : (
                  <ion-icon name="add-outline" className="add-icon"></ion-icon>
                )}
              </div>
            </button>

            <Collapse in={mobileJewelleryOpen}>
              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Earrings
                </a>
              </li>

              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Couple Rings
                </a>
              </li>

              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Necklace
                </a>
              </li>

              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Bracelets
                </a>
              </li>
            </Collapse>
          </li>

          <li className="menu-category">
            <button
              className="accordion-menu"
              onClick={handleMobilePerfume}
              data-accordion-btn
            >
              <p className="menu-title">Perfume</p>

              <div>
                {mobilePerfumeOpen ? (
                  <ion-icon
                    name="remove-outline"
                    className="remove-icon"
                  ></ion-icon>
                ) : (
                  <ion-icon name="add-outline" className="add-icon"></ion-icon>
                )}
              </div>
            </button>

            <Collapse in={mobilePerfumeOpen}>
              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Clothes Perfume
                </a>
              </li>

              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Deodorant
                </a>
              </li>

              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Flower Fragrance
                </a>
              </li>

              <li className="submenu-category">
                <a href="#" className="submenu-title">
                  Air Freshener
                </a>
              </li>
            </Collapse>
          </li> */}
          <li className="menu-category" data-mobile-contact-close-btn>
            <a onClick={() => goTo("contact")} className="menu-title">
              Contact Us
            </a>
          </li>
          <li className="menu-category" data-mobile-terms-close-btn>
            <a onClick={() => goTo("terms")} className="menu-title">
              Terms and Conditions
            </a>
          </li>
        </ul>

        <div className="menu-bottom">
          <p className="follow-us">
            Follow us on social medias for updates on our upcoming products with{" "}
            <span className="offer">60%</span> offer
          </p>
          <ul className="menu-social-container">
            <li>
              <a
                href={SOCIAL_MEDIA_LINKS.youtube}
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ion-icon name="logo-youtube"></ion-icon>
              </a>
            </li>
            {/* <li>
              <a
                href={SOCIAL_MEDIA_LINKS.facebook}
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ion-icon name="logo-facebook"></ion-icon>
              </a>
            </li> */}
            <li>
              <a
                href={SOCIAL_MEDIA_LINKS.twitter}
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ion-icon name="logo-twitter"></ion-icon>
              </a>
            </li>
            <li>
              <a
                href={SOCIAL_MEDIA_LINKS.instagram}
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Footer;
