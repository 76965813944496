import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import Header from "./Components/Header";
import ProductView from "./Pages/ProductView";
import Footer from "./Components/Footer";
import Checkout from "./Pages/Checkout";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import TermsAndConditions from "./Pages/TermsAndConditions";
import Messages from "./Pages/Messages";
import Cart from "./Pages/Cart";
import Successfull from "./Pages/Successfull";
import StepperCheckout from "./Pages/StepperCheckout";
import RazorPayTest from "./Pages/RazorPayTest";
import AddCategory from "./admin/Categories/pages/AddCategory";
import Products from "./admin/Products/Products";
import {
  getFinalCart,
  getCart,
  deleteCartItem,
  updateCartItem,
  updateFinalCart,
  clearCart,
} from "./admin/Categories/services/cartService";
import { getUserById } from "./admin/Categories/services/authService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AdminLogin from "./admin/adminAuth/AdminLogin";
import AdminDashboard from "./admin/AdminDashboard";
import AddEditProduct from "./admin/Products/AddEditProduct";
import Customers from "./admin/Customers";
import Orders from "./admin/Orders";
import OrderDetailsPage from "./admin/OrderDetailsPage";
import FavoritesList from "./Pages/FavoritesList";
import { addToFavorite } from "./admin/Categories/services/favoritesServices";
import LoginSignupDialog from "./Components/LoginSignupDialog";
import { getFavoritesByUserId } from "./admin/Categories/services/favoritesServices";
import UserProfile from "./Pages/UserProfile";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProductList from "./admin/Products/ProductList";
import Loader from "./Components/Loader";
import ProtectedRoute from "./admin/ProtectedRoute";
import Home from "./Pages/Home";
import Visitors from "./admin/visitors/Visitors";
// Main App function
function App() {
  return (
    <Router>
      <RoutesWrapper /> {/* This is where the routing logic lives */}
    </Router>
  );
}

// Separate component to handle routes and location
function RoutesWrapper() {
  const [userStatus, setUserStatus] = useState({ type: "none" }); // State to track user status

  const { pathname } = useLocation(); // Now it's safe to use useLocation inside Router
  const [cart, setCart] = useState([]);
  const [finalCart, setFinalCart] = useState([]);
  const [appLoading, setAppLoading] = useState(false); // New loading state
  const [userId, setUserId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("global_india_shop_token"); // Check for token
    const user_id = localStorage.getItem("global_india_shop_user_id"); // Check for session ID
    const sessionId = localStorage.getItem("global_india_shop_sessionId"); // Check for session ID
    console.log(token, "setUserStatus token");
    console.log(sessionId, "setUserStatus sessionId");
    if (user_id) {
      setUserId(user_id);
    }
    if (token) {
      setUserStatus({ type: "user", token }); // User is logged in
    } else if (sessionId) {
      setUserStatus({ type: "guest", sessionId }); // User is a guest
    } else {
      setUserStatus({ type: "none" }); // No user
    }
    // setAppLoading(false); // Loading done
  }, []);

  useEffect(() => {
    console.log(userStatus, "userStatus");
  }, [userStatus]);

  const setNewUser = () => {
    const user_id = localStorage.getItem("global_india_shop_user_id"); // Check for session ID
    console.log(user_id, "user_id");
    if (user_id) {
      setUserId(user_id);
      fetchUser(user_id);
    }
  };

  useEffect(() => {
    console.log(currentUser, "APPJS_CURRENT_USER");
    if (currentUser) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [currentUser]);

  const loadCart = async () => {
    // setAppLoading(true); // Start loading
    let savedSessionId = localStorage.getItem("global_india_shop_sessionId");
    if (savedSessionId) {
      try {
        const response = await getCart(savedSessionId);
        setCart(response.data?.items || []);
      } catch (error) {
        console.error("Error fetching cart:", error);
      } finally {
        // setAppLoading(false); // End loading
      }
    } else {
      // setAppLoading(false); // End loading if no session ID
    }
  };

  const loadFinalCart = async (selectedPaymentMethod) => {
    let savedSessionId = localStorage.getItem("global_india_shop_sessionId");
    let isOnlinePayment = selectedPaymentMethod === "online"; // assuming this is set when payment method changes
    if (savedSessionId) {
      try {
        const response = await getFinalCart(savedSessionId, isOnlinePayment);
        setFinalCart(response.data); // set the updated final cart details
      } catch (error) {
        console.error("Error fetching final cart:", error);
      }
    }
  };

  // Load cart and final cart whenever the route changes
  useEffect(() => {
    loadCart();
    loadFinalCart("online");
  }, [pathname]); // Call when pathname changes

  const handleDeleteCart = async (item) => {
    const result = await Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove this item from your cart? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      const savedSessionId = localStorage.getItem(
        "global_india_shop_sessionId"
      );

      if (!savedSessionId || !item?._id) {
        console.error("Session ID or item ID is missing");
        return;
      }
      try {
        await deleteCartItem(savedSessionId, item._id);
        toast.success("Cart Deleted!", {
          position: "top-center",
          autoClose: 2000,
        });
        await loadCart(); // Refresh cart after deletion
        await loadFinalCart("online");
      } catch (error) {
        await loadCart(); // Refresh cart after deletion
        await loadFinalCart("online");
        console.error("Error deleting cart item:", error);
        toast.error("Error deleting cart item", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } else {
      toast.info("Delete action canceled.", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const handleCartClear = async (item) => {
    let savedSessionId = localStorage.getItem("global_india_shop_sessionId");
    if (savedSessionId) {
      try {
        // Call the API to clear the cart
        await clearCart(savedSessionId);
        await loadCart();
        await loadFinalCart("online");
      } catch (error) {
        console.error("Error clearing the cart:", error);
        await loadCart();
        await loadFinalCart("online");
      }
    }
  };

  const handleQuantityChange = async (item, quantity) => {
    console.log(item, "item handleQuantityChange");
    console.log(quantity, "quantity handleQuantityChange");
    let savedSessionId = localStorage.getItem("global_india_shop_sessionId");

    const cartItem = {
      sessionId: savedSessionId,
      itemId: item?._id,
      quantity: quantity,
    };
    if (savedSessionId) {
      try {
        await updateCartItem(savedSessionId, cartItem); // Call the addToCart function
        console.log("Item added to cart");
        toast.success("Cart Updated!", {
          position: "top-center",
          autoClose: 1000,
        });
        await loadCart(); // Refresh cart after deletion
        await loadFinalCart("online");
        // Optionally refresh the cart or notify the user
      } catch (error) {
        await loadCart(); // Refresh cart after deletion
        await loadFinalCart("online");
        toast.error("Error updating cart", {
          position: toast?.POSITION?.TOP_CENTER,
          autoClose: 1000,
        });
      }
    }
  };

  const handleUpdateFinalCart = async (paymentMethod) => {
    setAppLoading(true);
    const isOnlinePayment = paymentMethod === "online";
    let savedSessionId = localStorage.getItem("global_india_shop_sessionId");
    if (savedSessionId) {
      try {
        await updateFinalCart(savedSessionId, isOnlinePayment); // Pass the correct parameter
        console.log("Item added to cart");
        await loadCart(); // Refresh cart after deletion
        await loadFinalCart(paymentMethod);
        // alert("handleUpdateFinalCart Success");
        toast.success(
          isOnlinePayment ? "Online Payment selected!" : "COD Charges Added!",
          {
            position: "top-center",
            autoClose: 3000,
          }
        );
        setAppLoading(false);

        // Optionally refresh the cart or notify the user
      } catch (error) {
        setAppLoading(false);
        await loadCart(); // Refresh cart after deletion
        await loadFinalCart(paymentMethod);
        toast.error("Error updating cart", {
          position: toast?.POSITION?.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }
  };

  // Update cart state when cartObj changes
  useEffect(() => {
    console.log(cart, "cart APPJS");
    console.log(finalCart, "finalCart APPJS");
  }, [cart, finalCart]); // Only run when cartObj changes

  useEffect(() => {
    if (userId) {
      fetchUser(userId);
    }
  }, [userId]);

  const fetchUser = (userId) => {
    getUserById(userId)
      .then((response) => {
        console.log(response, "getUserById");
        setCurrentUser(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });
  };

  const addfavoriteItems = async (cartItem) => {
    if (isLoggedIn && currentUser) {
      console.log(cartItem, "cartItem");
      try {
        const response = await addToFavorite(currentUser?.id, cartItem);
        console.log("Item added to favorites:", response.data);
        toast.success("Product added to favorites!", {
          position: "top-center",
          autoClose: 2000,
        });
        if (currentUser && currentUser?.id) {
          fetchFavorites();
        }
      } catch (error) {
        console.error(
          "Error adding cartItem to favorites:",
          error.response.data
        );
        toast.error(`${error.response.data?.message}`, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } else {
      toast.error("Please Login First", {
        position: "top-center",
        autoClose: 2000,
      });
      handleOpenDialog();
    }
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setNewUser();
  };

  const fetchFavorites = async () => {
    // alert("fetchFavorites");
    try {
      const response = await getFavoritesByUserId(currentUser?.id);
      console.log(response, "fetchFavorites");
      setFavorites(response.data.favoriteItems);
    } catch (err) {
    } finally {
      // setAppLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser?.id) {
      fetchFavorites();
    }
  }, [currentUser]);

  useEffect(() => {
    console.log(favorites, "favorites");
  }, [favorites]);

  useEffect(() => {
    console.log(appLoading, "appLoading");
  }, [appLoading]);

  return (
    <>
      <HeaderWrapper cart={cart} finalCart={finalCart} favorites={favorites} />
      <main>
        {/* {loading ? ( // Conditional rendering based on loading state
          <Loader />
        ) : (
         
        )} */}

        <Routes>
          <Route
            path="/drag-test"
            element={
              <DndProvider backend={HTML5Backend}>
                <ProductList />
              </DndProvider>
            }
          />
          <Route path="/" element={<Home />} />
          <Route path="/home/:currentCategory" element={<LandingPage />} />
          <Route path="/checkout-old" element={<Checkout />} />
          <Route path="/about-global-india-shop" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/cart" element={<Cart />} />
          <Route
            path="/favorites"
            element={
              <FavoritesList
                currentUser={currentUser}
                isLoggedIn={isLoggedIn}
                favorites={favorites}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <UserProfile
                currentUser={currentUser}
                handleOpenDialog={handleOpenDialog}
              />
            }
          />
          <Route path="/ordered-successfully" element={<Successfull />} />
          <Route
            path="/checkout"
            element={
              <StepperCheckout
                cartObj={cart}
                finalCartObj={finalCart}
                onDelete={handleDeleteCart}
                onAddFavorites={addfavoriteItems}
                onCartClear={handleCartClear}
                onQuantityChange={handleQuantityChange}
                onUpdateFinalCart={handleUpdateFinalCart}
                currentUser={currentUser}
                isLoggedIn={isLoggedIn}
                setNewUser={setNewUser}
                appLoading={appLoading}
              />
            }
          />
          <Route path="/RazorPayTest" element={<RazorPayTest />} />
          <Route path="/product/:id" element={<ProductView />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          >
            <Route path="add-category" element={<AddCategory />} />
            <Route
              path="/admin-dashboard/categories/:category_ID"
              element={
                <DndProvider backend={HTML5Backend}>
                  <Products />
                </DndProvider>
              }
            />
            <Route
              path="/admin-dashboard/add-products/:id"
              element={<AddEditProduct />}
            />
            <Route path="/admin-dashboard/users" element={<Customers />} />
            <Route path="/admin-dashboard/orders" element={<Orders />} />
            <Route path="/admin-dashboard/visitors" element={<Visitors />} />
            <Route
              path="/admin-dashboard/view-order"
              element={<OrderDetailsPage />}
            />
          </Route>
        </Routes>
      </main>
      <FooterWrapper cart={cart} finalCart={finalCart} favorites={favorites} />
      <ToastContainer />
      <LoginSignupDialog open={dialogOpen} onClose={handleCloseDialog} />
    </>
  );
}

// Component to pass cart information to the Header
const HeaderWrapper = ({ cart, finalCart, favorites }) => {
  return (
    <Header cartObj={cart} finalCartObj={finalCart} favorites={favorites} />
  );
};
// Component to pass cart information to the Header
const FooterWrapper = ({ cart, finalCart, favorites }) => {
  return (
    <Footer cartObj={cart} finalCartObj={finalCart} favorites={favorites} />
  );
};

export default App;
