import React, { useState } from "react";
import "../css/ProductsRow.css";
import { useNavigate } from "react-router-dom";

const ProductsRow = ({ products, from }) => {
  const navigate = useNavigate();

  console.log(products, "products_ProductsRow");
  console.log(from, "from_productsrow");

  const handleProductClick = (id) => {
    // alert("handleProductClick");
    // alert(id);
    navigate(`/product/${id}`);
  };

  return (
    <>
      <div className="product-container">
        {products?.map((product) => (
          <div
            className="product-card"
            key={product?.name}
            onClick={() =>
              handleProductClick(
                from === "favorites" ? product?.product_id : product?._id
              )
            }
          >
            <img
              src={`${product?.main_image.replace(/\\/g, "/")}`}
              alt={product?.main_image}
            />
            <div className="product-details">
              <div className="product-name">{product?.name}</div>
              <div className="product-type">{product?.type}</div>
              <div className="product-price">
                ₹{product?.price}
                <span className="product-before-price">
                  ₹{product?.before_price}
                </span>
                <span className="product-offer">
                  ( {product?.discount}% Off )
                </span>
              </div>
              {product?.type == "clothing" && (
                <>
                  {product?.sizes && product?.sizes?.length > 0 && (
                    <>
                      <div className="product-sizes">
                        {product?.sizes.map((size) => (
                          <span className="size" key={size}>
                            {size?.toUpperCase()}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
        {products.length == 0 && (
          <>
            <>
              {from === "discount-zone" && (
                <>
                  <div className="view-all-card">
                    <div className="view-all-wrapper">
                      <div>Welcome</div>
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        )}
      </div>

      {from === "favorites" && (
        <>
          <div className="continue-shop-wrapper">
            <div className="continue-shop-text">
              You have no favorites added
            </div>
            <button
              onClick={() => {
                navigate("/");
              }}
              className="login-btn"
            >
              Continue Shopping
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ProductsRow;
