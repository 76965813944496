import React from "react";
import { Grid, Card, CardContent, Typography, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import "../css/admin-css/OrderDetailsPage.css"; // Import the CSS file
import { services } from "../services/services";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OrderDetailsPage = () => {
  const location = useLocation();
  const { order } = location.state || {}; // Destructure the order object from state
  console.log(order, "Received order");
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success(`${text} copied to clipboard`, {
      position: "top-center",
      autoClose: 500,
    });
  };

  return (
    <div className="order-details-container">
      <h6 className="order-details-title">Order Details</h6>
      <Grid container spacing={4}>
        {/* Customer Address */}
        <Grid item xs={12} md={4}>
          <Card className="details-card">
            <CardContent>
              <Typography variant="h6" className="section-title">
                Customer Address
              </Typography>

              <Typography className="key-value">
                <strong>Name:</strong> {order.customerAddress.fullName}
                <IconButton
                  onClick={() => handleCopy(order.customerAddress.fullName)}
                  size="small"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Typography>

              <Typography className="key-value">
                <strong>Phone:</strong> {order.customerAddress.primaryPhone}
                <IconButton
                  onClick={() => handleCopy(order.customerAddress.primaryPhone)}
                  size="small"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Typography>

              <Typography className="key-value">
                <strong>Landmark:</strong> {order.customerAddress.landmark}
                <IconButton
                  onClick={() => handleCopy(order.customerAddress.landmark)}
                  size="small"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Typography>

              <Typography className="key-value">
                <strong>Address:</strong> {order.customerAddress.address}
                <IconButton
                  onClick={() => handleCopy(order.customerAddress.address)}
                  size="small"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Typography>

              <Typography className="key-value">
                <strong>Pincode:</strong> {order.customerAddress.pincode}
                <IconButton
                  onClick={() => handleCopy(order.customerAddress.pincode)}
                  size="small"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Typography>

              <Typography className="key-value">
                <strong>City:</strong> {order.customerAddress.city}
                <IconButton
                  onClick={() => handleCopy(order.customerAddress.city)}
                  size="small"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Typography>

              <Typography className="key-value">
                <strong>State:</strong> {order.customerAddress.state}
                <IconButton
                  onClick={() => handleCopy(order.customerAddress.state)}
                  size="small"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Typography>

              <Typography className="key-value">
                <strong>Complete Address:</strong>{" "}
                {` ${order.customerAddress.landmark} , ${order.customerAddress.address}`}
                <IconButton
                  onClick={() =>
                    handleCopy(
                      `${order.customerAddress.landmark}, ${order.customerAddress.address}`
                    )
                  }
                  size="small"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Full Cart (Items) */}
        <Grid item xs={12} md={4}>
          <Card className="details-card">
            <CardContent>
              <Typography variant="p" className="section-title">
                Items in Cart
              </Typography>
              {order.fullCart.map((item, index) => (
                <div key={index} className="item-card">
                  <Typography className="key-value">
                    <strong>Product Name:</strong> {item.name}
                  </Typography>
                  <Typography className="key-value">
                    <strong>Type:</strong> {item.type}
                  </Typography>
                  <Typography className="key-value">
                    <strong>Price:</strong> ₹{item.price}
                  </Typography>
                  <Typography className="key-value">
                    <strong>Discount:</strong> {item.discount}%
                  </Typography>
                  <Typography className="key-value">
                    <strong>Color:</strong> {item.color}
                  </Typography>
                  <Typography className="key-value">
                    <strong>Size:</strong> {item.size}
                  </Typography>
                  <Typography className="key-value">
                    <strong>Quantity:</strong> {item.quantity}
                  </Typography>
                  <img
                    src={`${item.main_image.replace(/\\/g, "/")}`}
                    alt={`Product ${index}`}
                    className="product-image"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid>

        {/* Final Cart (Summary) */}
        <Grid item xs={12} md={4}>
          <Card className="details-card">
            <CardContent>
              <Typography variant="p" className="section-title">
                Order Summary
              </Typography>
              <Typography className="key-value">
                <strong>Total Items:</strong> {order.finalCart.items}
              </Typography>
              <Typography className="key-value">
                <strong>Total Price:</strong> ₹{order.finalCart.totalPrice}
              </Typography>
              <Typography className="key-value">
                <strong>Discount:</strong> ₹{order.finalCart.totalDiscount}
              </Typography>
              <Typography className="key-value">
                <strong>MRP:</strong> ₹{order.finalCart.totalMRP}
              </Typography>
              <Typography className="key-value">
                <strong>Shipping:</strong> ₹{order.finalCart.shipping}
              </Typography>
              <Typography className="key-value">
                <strong>Delivery Charge:</strong> ₹
                {order.finalCart.deliveryCharge}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderDetailsPage;
