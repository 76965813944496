import React, { useEffect, useState } from "react";
import "../css/trustcard.css";

function EmailPage() {
  return (
    <>
      <div className="container my-4 p-0">
        <div className="email-main">
          <div className="email-title">
            Stay Updated on the Latest Men's Fashion! Sign up for Exclusive
            Offers and Updates.
          </div>
          <div className="email-box">
            <input
              type="text"
              placeholder="email"
              className="form-control email-input"
            />
            <button className="btn email-btn">Join us</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailPage;
