import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { getUserOrders } from "../admin/Categories/services/cartService";
import { services } from "../services/services";
const TabContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const UserProfile = ({ currentUser, handleOpenDialog }) => {
  console.log(currentUser, "currentUser");

  const [value, setValue] = useState(0);
  const [orders, setOrders] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchUsersOrders = async () => {
    // alert("fetchFavorites");
    try {
      const response = await getUserOrders(currentUser?.id);
      console.log(response, "fetchUsersOrders");
      setOrders(response?.orders);
    } catch {}
  };

  useEffect(() => {
    if (currentUser && currentUser?.id) {
      fetchUsersOrders();
    }
  }, [currentUser]);
  useEffect(() => {
    console.log(orders, "orders");
  }, [orders]);

  return (
    <>
      <div className="container mt-2">
        <div className="tabs-main">
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tabs example"
            >
              <Tab
                label="Billing Details"
                className="tabs-label"
                sx={{
                  color: "#212121",
                  fontFamily: " sans-serif",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: 1.4,
                  textTransform: "capitalize",
                  borderBottom: "2px solid transparent",
                  "&.Mui-selected": {
                    color: "#000000",
                    borderBottom: "2px solid #000000",
                  },
                }}
              />
              {/* <Tab
                label="Orders"
                className="tabs-label"
                sx={{
                  color: "#212121",
                  fontFamily: " sans-serif",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: 1.4,
                  textTransform: "capitalize",
                  borderBottom: "2px solid transparent",
                  "&.Mui-selected": {
                    color: "#000000",
                    borderBottom: "2px solid #000000",
                  },
                }}
              /> */}
              {/* <Tab
                label="Notifications"
                className="tabs-label"
                sx={{
                  color: "#212121",
                  fontFamily: " sans-serif",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: 1.4,
                  textTransform: "capitalize",
                  borderBottom: "2px solid transparent",
                  "&.Mui-selected": {
                    color: "#000000",
                    borderBottom: "2px solid #000000",
                  },
                }}
              /> */}
            </Tabs>
            <TabContent>
              {value === 0 && (
                <>
                  {currentUser && (
                    <>
                      <div>
                        <div className="payment-col-1">
                          <div class="checkout-card">
                            <div class="info-item">
                              <i class="bi bi-person-circle"></i>
                              <p class="info-label">Full Name:</p>
                              <p className="info-value">
                                {currentUser?.firstName &&
                                  currentUser.firstName
                                    .charAt(0)
                                    .toUpperCase() +
                                    currentUser.firstName
                                      .slice(1)
                                      .toLowerCase()}
                              </p>
                            </div>
                            <div class="info-item">
                              <i class="bi bi-telephone-fill"></i>
                              <p class="info-label">Phone Number:</p>
                              <p class="info-value">
                                +91 {currentUser?.phoneNumber}
                              </p>
                            </div>

                            {currentUser?.addresses.length &&
                              currentUser?.addresses?.map((address) => (
                                <>
                                  <div class="info-item">
                                    <i class="bi bi-house-fill"></i>
                                    <p class="info-label">Address:</p>
                                    <p class="info-value">{address?.address}</p>
                                  </div>
                                  <div class="info-item">
                                    <i class="bi bi-geo-alt-fill"></i>
                                    <p class="info-label">Landmark:</p>
                                    <p class="info-value">
                                      {address?.landmark}
                                    </p>
                                  </div>
                                  <div class="info-item">
                                    <i class="bi bi-pin-map-fill"></i>
                                    <p class="info-label">Pincode:</p>
                                    <p class="info-value">{address?.pincode}</p>
                                  </div>
                                  <div class="info-item">
                                    <i class="bi bi-geo-alt-fill"></i>
                                    <p class="info-label">City:</p>
                                    <p class="info-value">{address?.city}</p>
                                  </div>
                                  <div class="info-item">
                                    <i class="bi bi-flag-fill"></i>
                                    <p class="info-label">State:</p>
                                    <p class="info-value">{address?.state}</p>
                                  </div>
                                </>
                              ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {!currentUser && (
                    <>
                      <div>
                        <span className="allready">Already have a account</span>
                        <span className="login-main" onClick={handleOpenDialog}>
                          Login / Signup
                        </span>
                      </div>
                    </>
                  )}
                </>
              )}
              {value === 1 && (
                <div className="tabs-description">
                  {orders && orders.length > 0 && (
                    <>
                      {orders.map((order) => (
                        <>
                          <div className="cart-card">
                            <div className="cart-col-one">
                              <div className="cart-image-section">
                                <img
                                  className="cart-image"
                                  src={`${order?.fullCart?.main_image?.replace(
                                    /\\/g,
                                    "/"
                                  )}`}
                                />
                              </div>
                              <div className="cart-content">
                                <div className="cartpname">
                                  {order?.fullCart?.name}
                                </div>
                                <div className="cart-pricing-section pricing-section">
                                  <span className="price-style cart-price">
                                    ₹{order?.fullCart?.price}
                                  </span>
                                  <div className="pricing-section">
                                    <span className="previous-price ">
                                      ₹ {order?.fullCart?.before_price}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <span className="cart-save-name ">
                                    You Save{" "}
                                  </span>
                                  <span className="cart-save-price">
                                    ₹{order?.fullCart?.savedPrice}{" "}
                                  </span>
                                </div>

                                <div className="des-product">
                                  <div className="des-product-color">
                                    <span>Color :</span>
                                    <span className="des-value">
                                      {" "}
                                      {order?.fullCart?.color}
                                    </span>
                                  </div>
                                  <div className="des-product-size">
                                    <span>Size :</span>
                                    <span className="des-value">
                                      {" "}
                                      {order?.fullCart?.size}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>
              )}

              {/* {value === 2 && <div>22222222222</div>} */}
            </TabContent>
          </Box>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
