import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { createProduct, updateProduct, deleteProduct } from "./productService";
import "../../css/admin-css/Products.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./colorPicker.module.css"; // Your existing CSS for styling
import { IconButton, TextField, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
const AddEditProduct = () => {
  const location = useLocation();
  const { product_recieved } = location.state || {}; // Destructure the product object from state

  console.log(product_recieved, "Received Product");
  const [videoLinkInput, setVideoLinkInput] = useState("");
  const [colors, setColors] = useState([]);
  const [videosList, setVideosList] = useState([]);
  const [status, setStatus] = useState("");
  const [discountList, setDiscountList] = useState([]);
  const [product, setProduct] = useState(product_recieved);
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    price: "",
    before_price: "",
    discount: "",
    main_image: null,
    imageList: [],
    specifications: null,
    features: null,
    moreInfo: null,
    sizes: [],
    colors: [],
    youtubeLinks: [],
    status: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [editProductId, setEditProductId] = useState(null);

  const { id } = useParams();
  console.log(id, "id");
  const navigate = useNavigate();
  const sizes = ["S", "M", "L", "XL", "XXL", "XXXL"];
  // State to store the colors selected from each image

  const isBase64 = (string) => {
    return string.startsWith("data:image/");
  };

  useEffect(() => {
    if (product_recieved) {
      handleEdit(product_recieved);
    }
  }, [product_recieved]);

  const handleEdit = (product) => {
    console.log(product, "product_EDIT");
    let sizes = product.sizes;
    if (typeof sizes === "string") {
      sizes = sizes.split(","); // Split string into array by commas
    }
    let colors = product.colors;
    if (typeof colors === "string") {
      colors = colors.split(","); // Split string into array by commas
    }
    setColors(product?.colors);
    setFormData({
      name: product.name,
      type: product.type,
      price: product.price,
      before_price: product.before_price,
      discount: product.discount,
      main_image: product.main_image,
      imageList: product.imageList,
      specifications: product.specifications,
      features: product.features,
      moreInfo: product.moreInfo,
      sizes: sizes,
      youtubeLinks: product.youtubeLinks,
      colors: colors,
      status: status,
    });
    console.log(formData, "formData_EDIT");
    setEditMode(true);
    setEditProductId(product._id);
  };

  const specificationChange = (value) => {
    if (value !== formData.specifications) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        specifications: value,
      }));
      console.log(value, "value");
    }
  };

  const featuresChange = (value) => {
    if (value !== formData.features) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        features: value,
      }));
      console.log(value, "value");
    }
  };

  const moreInfoChange = (value) => {
    if (value !== formData.moreInfo) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        moreInfo: value,
      }));
      console.log(value, "value");
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "main_image") {
      setFormData({ ...formData, main_image: files[0] });
    } else if (name === "imageList") {
      setFormData({ ...formData, imageList: [...files] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0]; // Get the first file from the input
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create a blob URL for preview

      setFormData((prevFormData) => ({
        ...prevFormData,
        main_image: file, // Store the file for submission
        main_image_preview: imageUrl, // Store the preview URL
      }));
    }
  };

  const handleImageListUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImagePreviews = files.map((file) => URL.createObjectURL(file));

    setFormData((prevFormData) => ({
      ...prevFormData,
      imageList: [
        ...prevFormData.imageList,
        ...files, // Store the actual file objects for uploading
      ],
      imagePreviews: [
        ...(prevFormData.imagePreviews || []),
        ...newImagePreviews, // Store the preview URLs
      ],
    }));
  };

  const deleteImageListAll = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      imageList: [], // Set imageList to an empty array
      imagePreviews: [], // Optionally clear the previews as well
      colors: [],
    }));
    setColors([]);
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    const productData = new FormData();
    // Append each field to FormData
    for (const key in formData) {
      if (key === "main_image") {
        // Append the main image file
        productData.append(key, formData[key]);
      } else if (key === "imageList") {
        // Append each file from the imageList array
        formData.imageList.forEach((file, index) => {
          productData.append("imageList", file); // Appending files one by one
        });
      } else {
        // Append other non-file fields
        productData.append(key, formData[key]);
      }
    }
    // Call the createProduct API function to submit the form data
    await createProduct(id, productData);
    // Fetch the updated product list and reset the form
    navigate(`/admin-dashboard/categories/${id}`);
    resetForm();
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    const productData = new FormData();
    for (const key in formData) {
      if (key === "main_image") {
        // Append the main image file
        productData.append(key, formData[key]);
      } else if (key === "imageList") {
        // Append each file from the imageList array
        formData.imageList.forEach((file, index) => {
          productData.append("imageList", file); // Appending files one by one
        });
      } else {
        // Append other non-file fields
        productData.append(key, formData[key]);
      }
    }
    await updateProduct(editProductId, productData);
    navigate(`/admin-dashboard/categories/${id}`);
    resetForm();
  };

  const handleDeleteProduct = async (productId) => {
    await deleteProduct(productId);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      type: "",
      price: "",
      before_price: "",
      discount: "",
      main_image: null,
      imageList: [],
      specifications: null,
      features: null,
      moreInfo: null,
      sizes: [],
      colors: [],
      status: "",
      youtubeLinks: [],
    });
    setEditMode(false);
    setEditProductId(null);
  };

  // Handle size selection
  const handleSizeSelect = (size) => {
    setFormData((prevFormData) => {
      const currentSizes = prevFormData.sizes || []; // Ensure sizes is always an array
      const newSizes = currentSizes.includes(size)
        ? currentSizes.filter((s) => s !== size) // Remove size if already selected
        : [...currentSizes, size]; // Add size if not selected
      return { ...prevFormData, sizes: newSizes };
    });
  };

  const handleCopyColor = async (color) => {
    await navigator.clipboard.writeText(color);
    console.log(color, "color");
    // alert(`Copied ${color} to clipboard!`);
  };

  const openEyeDropper = async (index) => {
    if (window.EyeDropper) {
      let eyeDropper = new window.EyeDropper(); // Use window.EyeDropper
      try {
        const { sRGBHex } = await eyeDropper.open();
        // setColors((prevColors) => ({ ...prevColors, [index]: sRGBHex }));
        setColors((prevColors) => {
          const updatedColors = [...prevColors]; // Create a copy of the existing array
          updatedColors[index] = sRGBHex; // Update the color at the given index
          return updatedColors; // Return the updated array
        });
        // Update formData colors array
        setFormData((prevFormData) => ({
          ...prevFormData,
          colors: [
            ...prevFormData.colors.slice(0, index),
            sRGBHex,
            ...prevFormData.colors.slice(index + 1),
          ],
        }));
      } catch (error) {
        console.error("Error using EyeDropper:", error);
      }
    } else {
      // alert("Your browser does not support the EyeDropper API.");
    }
  };

  useEffect(() => {
    console.log(product, "product");
  }, [product]);
  useEffect(() => {
    console.log(formData, "formData");
  }, [formData]);
  useEffect(() => {
    console.log(colors, "colors");
  }, [colors]);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ size: ["small", false, "large", "huge"] }], // Font size options
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const handleVideoLinkChange = (e) => {
    setVideoLinkInput(e.target.value); // Update input field state
  };

  const handleAddVideo = () => {
    const link = videoLinkInput.trim();
    // Validate YouTube link
    const isValidYouTubeLink = (url) => {
      const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
      return regex.test(url);
    };

    if (
      link &&
      isValidYouTubeLink(link) &&
      !formData.youtubeLinks.includes(link)
    ) {
      // Update formData's youtubeLinks array
      setFormData((prev) => ({
        ...prev,
        youtubeLinks: [...prev.youtubeLinks, link],
      }));
      setVideoLinkInput(""); // Clear input field
    }
  };

  const handleDeleteVideo = (index) => {
    const updatedYoutubeLinks = formData.youtubeLinks.filter(
      (_, i) => i !== index
    );
    setFormData((prev) => ({
      ...prev,
      youtubeLinks: updatedYoutubeLinks,
    }));
  };

  const getVideoId = (url) => {
    if (url) {
      const urlObj = new URL(url);
      const pathnameParts = urlObj.pathname.split("/");
      const videoId = pathnameParts[pathnameParts.length - 1];
      return videoId;
    }
  };

  return (
    <div className="container-fluid mt-1">
      {/* <h6 className="mb-4">Products for Category ID: {id}</h6> */}
      <div className="row">
        <div className="col-md-12">
          <h6 className="mb-4">{editMode ? "Edit Product" : "Add Product"}</h6>
          <form onSubmit={editMode ? handleUpdateProduct : handleAddProduct}>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="mb-2">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-2">
                  <label htmlFor="type" className="form-label">
                    Type
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="type"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="price" className="form-label">
                    Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="before_price" className="form-label">
                    Before Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="before_price"
                    name="before_price"
                    value={formData.before_price}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="discount" className="form-label">
                    Discount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="discount"
                    name="discount"
                    value={formData.discount}
                    onChange={handleChange}
                  />
                </div>
                <div className="description-section">
                  <div className="mb-3">
                    <ReactQuill
                      value={formData?.specifications}
                      onChange={specificationChange}
                      placeholder="Specifications"
                      modules={modules}
                      // style={{ height: '150px' }} // Set height here
                    />
                  </div>
                  <div className="mb-3">
                    <ReactQuill
                      value={formData?.features}
                      onChange={featuresChange}
                      placeholder="Features"
                    />
                  </div>
                  <div className="mb-3">
                    <ReactQuill
                      value={formData?.moreInfo}
                      onChange={moreInfoChange}
                      placeholder="More Info"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="mb-2">
                  <label htmlFor="main_image" className="form-label">
                    Main Image
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="main_image"
                    name="main_image"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </div>
                <div className="mb-2 image-preview-container">
                  <p>main Image</p>
                  <div className="d-flex flex-wrap">
                    {formData.main_image_preview || formData.main_image ? (
                      <img
                        src={
                          formData.main_image_preview // If there's a preview from upload, use it
                            ? formData.main_image_preview
                            : `${formData.main_image.replace(/\\/g, "/")}` // If it's a server path, construct the full URL
                        }
                        alt="Main Preview"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "5px",
                          boxShadow: "0 0 5px rgba(0,0,0,0.3)",
                        }}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="imageList" className="form-label">
                    Image List
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="imageList"
                    name="imageList"
                    accept="image/*"
                    multiple
                    onChange={handleImageListUpload}
                  />
                </div>

                <div className="mb-2 image-preview-container">
                  <div className="image-list-delete-wrap">
                    <p className="">Images List</p>
                    <i
                      className="bi bi-trash2-fill image-list-delete"
                      onClick={deleteImageListAll}
                    ></i>
                  </div>

                  <div className="d-flex flex-wrap">
                    {(formData.imagePreviews || formData.imageList).map(
                      (image, index) => (
                        <div key={index} className="image-list-image">
                          <img
                            src={
                              typeof image === "string"
                                ? image.includes("blob")
                                  ? image // If it's a blob (newly uploaded file), use it directly
                                  : `${image.replace(/\\/g, "/")}` // If it's from server, adjust the path
                                : URL.createObjectURL(image) // If it's a File object (newly uploaded), create a URL
                            }
                            alt={`Image ${index + 1}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "5px",
                              boxShadow: "0 0 5px rgba(0,0,0,0.3)",
                            }}
                            onClick={() => openEyeDropper(index)} // Click to pick color
                          />
                          <div className={styles.formSection}>
                            <button
                              className={styles.selectedColor}
                              style={{ background: colors[index] || "#ffffff" }}
                              onClick={() =>
                                handleCopyColor(colors[index] || "#ffffff")
                              }
                            >
                              <span>{colors[index] || "Pick a color"}</span>
                            </button>
                          </div>
                          <div
                            className="custom-close-btn"
                            onClick={() => {
                              const newPreviews = (
                                formData.imagePreviews || formData.imageList
                              ).filter((_, i) => i !== index);
                              setFormData({
                                ...formData,
                                imageList: newPreviews, // Adjust the image list based on the preview array
                                imagePreviews: newPreviews, // Update previews if needed
                              });
                            }}
                          >
                            <i className="bi bi-x-circle-fill image-delete"></i>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <div className="sizes-section">
                    <div className="size-boxes">
                      {sizes.map((size) => (
                        <div
                          key={size}
                          className={`size-box ${
                            formData.sizes.includes(size) ? "selected" : ""
                          }`}
                          onClick={() => handleSizeSelect(size)}
                        >
                          {size}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="video-link-manager">
                    <div className="input-container mb-2">
                      <TextField
                        label="YouTube Video Link"
                        variant="outlined"
                        value={videoLinkInput}
                        onChange={handleVideoLinkChange}
                        fullWidth
                      />
                      <IconButton
                        onClick={handleAddVideo}
                        color="primary"
                        className="add-button"
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </div>

                  {/* {formData?.youtubeLinks.map((link, index) => {
                    const videoId = getVideoId(link);
                    return (
                      <div key={index} style={{ marginBottom: "20px" }}>
                        <iframe
                          width="560"
                          height="315"
                          src={`https://www.youtube.com/embed/${videoId}`}
                          title={`YouTube video ${index + 1}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    );
                  })} */}

                  <div className="added-videos">
                    {formData?.youtubeLinks.map((link, index) => {
                      const videoId = getVideoId(link);
                      const isShort = link.includes("/shorts/");
                      // Set the base URL with rel=0 for non-shorts videos
                      const src = isShort
                        ? `https://www.youtube.com/embed/${videoId}` // No additional params for shorts
                        : `https://www.youtube.com/embed/${videoId}?rel=0`;

                      return (
                        <div key={index} className="video-container">
                          <iframe
                            width="100%" // Full width of the container
                            height="315"
                            src={src}
                            title={`YouTube video ${index + 1}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />

                          <div className="delete-video">
                            <IconButton
                              className="delete-button"
                              onClick={() => handleDeleteVideo(index)}
                              color="secondary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <button type="submit" className="btn btn-primary mt-3">
                    {editMode ? "Update Product" : "Add Product"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEditProduct;
