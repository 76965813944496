// services/adminService.js

import axios from "axios";

// Set the base URL for your API
const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/admin`; // Adjust this URL to match your backend

// Create an Axios instance for admin APIs
const adminApi = axios.create({
  baseURL: BASE_URL,
});

// Interceptor to add the JWT token to every request
adminApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("adminToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Add the token to the Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to register a new admin
export const registerAdmin = async (adminData) => {
  try {
    const response = await adminApi.post("/register", adminData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data; // Return the response data
  } catch (error) {
    throw error.response?.data || { message: "Error registering admin" }; // Handle errors
  }
};

// Function to log in an admin
export const loginAdmin = async (credentials) => {
  try {
    const response = await adminApi.post("/login", credentials, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data; // Return the response data
  } catch (error) {
    throw error.response?.data || { message: "Error logging in" }; // Handle errors
  }
};

// Function to get user orders
export const getUserOrders = async () => {
  try {
    const response = await adminApi.get("/orders"); // Adjust the endpoint as necessary
    return response.data; // Return the response data
  } catch (error) {
    throw error.response?.data || { message: "Error fetching user orders" }; // Handle errors
  }
};

// Function to get all users
export const getAllUsers = async () => {
  try {
    const response = await adminApi.get(
      `${process.env.REACT_APP_BASE_URL}/api/auth/users`
    ); // Full endpoint
    return response.data; // Return the response data
  } catch (error) {
    throw error.response?.data || { message: "Error fetching users" }; // Handle errors
  }
};

// Function to get all users
export const getAllOrders = async () => {
  try {
    const response = await adminApi.get(
      `${process.env.REACT_APP_BASE_URL}/api/cart/ordersList`
    ); // Full endpoint
    return response.data; // Return the response data
  } catch (error) {
    throw error.response?.data || { message: "Error fetching users" }; // Handle errors
  }
};

// Function to update an order status
export const updateOrder = async (orderId, orderStatus) => {
  try {
    const response = await adminApi.put(
      `${process.env.REACT_APP_BASE_URL}/api/cart/orders/update/${orderId}`, // Full endpoint
      { orderStatus } // Send the new status in the request body
    );
    return response.data; // Return the response data
  } catch (error) {
    throw error.response?.data || { message: "Error updating order" }; // Handle errors
  }
};
