import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import { updateProductOrder } from "./productService";
import {
  createProduct,
  getProductsByCategory,
  updateProduct,
  deleteProduct,
} from "./productService";
import "../../css/admin-css/Products.css";
import "react-quill/dist/quill.snow.css";
import ImageListModal from "./ImageListModal";
import DescriptionModal from "./DescriptionModal";
import ProductItem from "./ProductItem";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const Products = () => {
  const { category_ID } = useParams();
  console.log(category_ID, "category_ID");
  const navigate = useNavigate();
  const sizes = ["S", "M", "L", "XL", "XXL", "XXXL"];
  // State to store the colors selected from each image
  const [colors, setColors] = useState({});
  const [discountList, setDiscountList] = useState([]);
  const [products, setProducts] = useState([]);
  const [status, setStatus] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    type: "",
    price: "",
    before_price: "",
    discount: "",
    main_image: null,
    imageList: [],
    specifications: null,
    features: null,
    moreInfo: null,
    sizes: [],
    colors: [],
    status: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [editProductId, setEditProductId] = useState(null);

  const isBase64 = (string) => {
    return string.startsWith("data:image/");
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const response = await getProductsByCategory(category_ID, "admin");
    setProducts(response.data);
  };

  const handleEdit = (product) => {
    console.log(product, "product_EDIT");
    let product_recieved = product;
    navigate(`/admin-dashboard/add-products/${category_ID}`, {
      state: { product_recieved },
    });
    let sizes = product.sizes;
    if (typeof sizes === "string") {
      sizes = sizes.split(","); // Split string into array by commas
    }
    let colors = product.colors;
    if (typeof colors === "string") {
      colors = colors.split(","); // Split string into array by commas
    }
    setFormData({
      name: product.name,
      type: product.type,
      price: product.price,
      before_price: product.before_price,
      discount: product.discount,
      main_image: product.main_image,
      imageList: product.imageList,
      specifications: product.specifications,
      features: product.features,
      moreInfo: product.moreInfo,
      sizes: sizes,
      colors: colors,
      status: status,
    });
    console.log(formData, "formData_EDIT");
    setEditMode(true);
    setEditProductId(product._id);
  };

  const handleDeleteProduct = async (productId) => {
    const result = await Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove this prouct?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        await deleteProduct(productId);
        fetchProducts();
        toast.success("Product Deleted!", {
          position: "top-center",
          autoClose: 2000,
        });
      } catch (error) {
        toast.error("Error deleting product", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } else {
      toast.info("Delete action canceled.", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const addProduct = async () => {
    navigate(`/admin-dashboard/add-products/${category_ID}`);
  };

  useEffect(() => {
    console.log(formData, "formData");
  }, [formData]);
  useEffect(() => {
    console.log(colors, "colors");
  }, [colors]);

  const [selectedImages, setSelectedImages] = useState([]);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState({});
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);

  const handleOpenImageModal = (images) => {
    setSelectedImages(images);
    setOpenImageModal(true);
  };

  const handleOpenDescriptionModal = (specifications, features, moreInfo) => {
    setSelectedDescription({ specifications, features, moreInfo });
    setOpenDescriptionModal(true);
  };

  const moveProduct = (fromIndex, toIndex) => {
    const updatedProducts = [...products];
    const [movedProduct] = updatedProducts.splice(fromIndex, 1); // Remove product from the old index
    updatedProducts.splice(toIndex, 0, movedProduct); // Insert it at the new index
    setProducts(updatedProducts);

    // Update the product order in the database
    updateProductOrderInDatabase(updatedProducts);
  };

  const updateProductOrderInDatabase = async (updatedProducts) => {
    // Implement your API call to update the product order in the database here
    console.log("Updating product order in database", updatedProducts);

    try {
      const productOrder = updatedProducts.map((product) => product._id);
      const response = await updateProductOrder(category_ID, productOrder);
      if (response.status === 200) {
        console.log(
          "Product order successfully updated in the database",
          response.data
        );
      } else {
        console.error("Failed to update product order", response.data);
      }
    } catch (error) {
      console.error("Error while updating product order in database", error);
    }
  };

  const handleStatusToggle = async (product) => {
    const newStatus = product.status === "active" ? "inactive" : "active"; // Toggle status
    const productData = { ...product, status: newStatus }; // Prepare updated data
    const result = await Swal.fire({
      title: `Confirm Status Change`,
      text: `Are you sure you want to change the status of this product to ${newStatus}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, make it ${newStatus}`,
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        await updateProduct(product._id, productData); // Call your updateProduct API
        fetchProducts();

        toast.success("Product Updated!", {
          position: "top-center",
          autoClose: 2000,
        });
      } catch (error) {
        toast.error("Error updating product", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } else {
      toast.info("updating action canceled.", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="container-fluid mt-1">
      {/* <h6 className="mb-4">Products for Category ID: {id}</h6> */}
      <div className="product-header">
        <h6 className="mb-4">Product List</h6>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => addProduct()}
        >
          Add Product
        </button>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-lg-12">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Main Image</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Price (₹)</TableCell>
                      <TableCell>Before Price (₹)</TableCell>
                      <TableCell>Discount (%)</TableCell>
                      <TableCell>Sizes</TableCell>
                      <TableCell>Colors</TableCell>
                      <TableCell>Actions</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product, index) => (
                      <ProductItem
                        key={product.id}
                        product={product}
                        index={index}
                        moveProduct={moveProduct}
                        handleOpenImageModal={handleOpenImageModal}
                        handleDeleteProduct={handleDeleteProduct}
                        handleOpenDescriptionModal={handleOpenDescriptionModal}
                        handleEdit={handleEdit}
                        handleStatusToggle={handleStatusToggle}
                      />
                    ))}
                  </TableBody>
                </Table>

                {/* Image List Modal */}
                <ImageListModal
                  open={openImageModal}
                  images={selectedImages}
                  onClose={() => setOpenImageModal(false)}
                />

                {/* Description Modal */}
                <DescriptionModal
                  open={openDescriptionModal}
                  description={selectedDescription}
                  onClose={() => setOpenDescriptionModal(false)}
                />
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
