import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import "../css/tabs.css";

const TabContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const BulletPoints = styled("ul")(({ theme }) => ({
  listStyleType: "disc",
  marginLeft: theme.spacing(2.5),
}));

const KeyFeatures = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2.5),
  fontWeight: "bold",
}));

function TabComponent({ product }) {
  console.log(product, "product TabComponent");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const watchDetails = {
    Brand: "Generic Brand",
    Model: "Standard Model",
    Movement: "Quartz",
    CaseMaterial: "Stainless Steel",
    CaseDiameter: "42 mm",
    WaterResistance: "100 m",
    StrapMaterial: "Silicone",
    GlassType: "Mineral Glass",
    DialColor: "White",
    Weight: "200 grams",
    Functions: "Time",
  };

  return (
    <div className="tabs-main">
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="tabs example">
          <Tab
            label="Specification"
            className="tabs-label"
            sx={{
              color: "#212121",
              fontFamily: " sans-serif",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: 1.4,
              textTransform: "capitalize",
              borderBottom: "2px solid transparent",
              "&.Mui-selected": {
                color: "#000000",
                borderBottom: "2px solid #000000",
              },
            }}
          />
          <Tab
            label="Features"
            className="tabs-label"
            sx={{
              color: "#212121",
              fontFamily: " sans-serif",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: 1.4,
              textTransform: "capitalize",
              borderBottom: "2px solid transparent",
              "&.Mui-selected": {
                color: "#000000",
                borderBottom: "2px solid #000000",
              },
            }}
          />
          <Tab
            label={
              product?.type == "Clothing"
                ? "Size Chart"
                : product?.type == "watches"
                ? "More Info"
                : ""
            }
            className="tabs-label"
            sx={{
              color: "#212121",
              fontFamily: " sans-serif",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: 1.4,
              textTransform: "capitalize",
              borderBottom: "2px solid transparent",
              "&.Mui-selected": {
                color: "#000000",
                borderBottom: "2px solid #000000",
              },
            }}
          />
        </Tabs>
        <TabContent>
          {value === 0 && (
            <div>
              <div className="tabs-specification">
                <div className="product-info">
                  <div
                    className="product-features"
                    dangerouslySetInnerHTML={{
                      __html: product?.specifications,
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {value === 1 && (
            <div className="tabs-description">
              <div className="tabs-specification">
                <div
                  className="product-features"
                  dangerouslySetInnerHTML={{
                    __html: product?.features,
                  }}
                />
              </div>
            </div>
          )}

          {value === 2 && (
            <div>
              {/* <div
                className="product-features"
                dangerouslySetInnerHTML={{ __html: product?.moreInfo }}
              /> */}
              {product?.type == "Clothing" && (
                <>
                  <table className="product-details">
                    <thead>
                      <tr>
                        <th className="label">Size</th>
                        <th className="label">Chest</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="value">S</td>
                        <td className="value">36 to 38 inches</td>
                      </tr>
                      <tr>
                        <td className="value">M</td>
                        <td className="value">38 to 40 inches</td>
                      </tr>
                      <tr>
                        <td className="value">L</td>
                        <td className="value">40 to 42 inches</td>
                      </tr>
                      <tr>
                        <td className="value">XL</td>
                        <td className="value">42 to 44 inches</td>
                      </tr>
                      <tr>
                        <td className="value">XXL</td>
                        <td className="value">44 to 46 inches</td>
                      </tr>
                      <tr>
                        <td className="value">XXXL</td>
                        <td className="value">46 to 48 inches</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              {product?.type == "watches" && (
                <>
                  <div className="table-container">
                    <table className="watch-details-table">
                      <thead>
                        <tr>
                          <th>Feature</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(watchDetails).map(
                          ([feature, details]) => (
                            <tr key={feature}>
                              <td className="feature-names">{feature}</td>
                              <td>{details}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          )}
        </TabContent>
      </Box>
    </div>
  );
}

export default TabComponent;
