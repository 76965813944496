// src/services/cartService.js
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/cart`; // Adjust this URL to match your backend

// Function to start a session
export const startSession = async () => {
  const response = await axios.get(`${BASE_URL}/start-session`);
  return response.data; // Assuming your backend sends back { sessionId: '...' }
};

export const transferCart = (sessionId, userId) => {
  return axios.post(`${BASE_URL}/transferCart`, {
    sessionId,
    userId,
  });
};

export const addToCart = (sessionId, cartItem) => {
  return axios.post(`${BASE_URL}/add`, {
    sessionId,
    ...cartItem,
  });
};

export const updateCartItem = (sessionId, updatedItem) => {
  return axios.put(`${BASE_URL}/update`, {
    sessionId,
    ...updatedItem,
  });
};

export const deleteCartItem = (sessionId, itemId) => {
  return axios.delete(`${BASE_URL}/delete/${itemId}`, {
    data: { sessionId },
  });
};

export const clearCart = (sessionId) => {
  return axios.delete(`${BASE_URL}/clearCart`, {
    data: { sessionId }, // Send sessionId in the request body
  });
};

export const getFinalCart = (sessionId, isOnlinePayment) => {
  return axios.get(`${BASE_URL}/getFinalCart`, {
    params: { sessionId, isOnlinePayment },
  });
};

export const getCart = (sessionId) => {
  return axios.get(`${BASE_URL}/getCart`, {
    params: { sessionId },
  });
};

export const updateFinalCart = (sessionId, isOnlinePayment) => {
  return axios.get(
    `${BASE_URL}/updateFinalCart?sessionId=${sessionId}&isOnlinePayment=${isOnlinePayment}`
  );
};

export const placeOrder = (orderData) => {
  return axios.post(`${BASE_URL}/placeOrder`, orderData);
};

// Function to get orders for a specific user by userId
export const getUserOrders = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/orders/${userId}`, {
      headers: {
        "Content-Type": "application/json", // Ensure this is set correctly
      },
    });
    return response.data; // Return the order data
  } catch (error) {
    console.error("Error fetching user orders:", error);
    throw error; // Rethrow the error for further handling
  }
};
