import React, { useEffect } from "react";
import "../css/aboutus.css";
import MainFooter from "../Components/MainFooter";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {" "}
      <div className="about-us">
        <h6>About Global India Shop</h6>

        <p>
          <strong>Global India Shop</strong> is a leading online marketplace
          dedicated to bringing you the best products from across India. Founded
          with a passion for quality and customer satisfaction, we aim to
          provide a convenient and enjoyable shopping experience for everyone.
        </p>

        <h6>Our Mission</h6>
        <p>
          At Global India Shop, our mission is to deliver exceptional value and
          service to our customers. We strive to offer a diverse range of
          high-quality products at competitive prices, ensuring that every
          shopping experience is smooth and satisfying.
        </p>

        <h6>What We Offer</h6>
        <p>
          Our extensive product catalog includes everything from the latest
          electronics and stylish clothing to essential home goods and beauty
          products. We carefully curate our selection to meet the needs and
          preferences of our customers, so you can find exactly what you're
          looking for, all in one place.
        </p>

        <h6>Exclusive Offers</h6>
        <p>
          We are committed to providing our customers with unbeatable value.
          That's why we offer a generous <strong>60% discount</strong> on all
          our products. Whether you're shopping for yourself or looking for the
          perfect gift, you'll enjoy significant savings on our entire range.
        </p>

        <h6>Convenience at Your Fingertips</h6>
        <p>
          To make your shopping experience as convenient as possible, we offer{" "}
          <strong>cash on delivery</strong> for all our products. This means you
          can shop with confidence, knowing you can pay for your order upon
          delivery, right at your doorstep.
        </p>

        <h6>Customer Satisfaction</h6>
        <p>
          Your satisfaction is our top priority. Our dedicated customer support
          team is here to assist you with any questions or concerns you may
          have. We are committed to resolving any issues promptly and ensuring
          that you have a positive shopping experience with us.
        </p>

        <h6>Our Values</h6>
        <p>
          We believe in operating with integrity, transparency, and a
          customer-centric approach. Our values guide everything we do, from
          selecting the best products to providing outstanding service. We are
          here to build lasting relationships with our customers and earn their
          trust through our actions.
        </p>

        <h6>Future Goals</h6>
        <p>
          As we look to the future, we aim to expand our product offerings and
          enhance our services to better serve our customers. We are
          continuously exploring new opportunities and innovations to ensure
          that Global India Shop remains at the forefront of the online shopping
          experience.
        </p>

        <h6>Thank You!</h6>
        <p>
          Thank you for choosing <strong>Global India Shop</strong>. We are
          excited to have you as part of our community and look forward to
          serving you with the best products and exceptional service. Happy
          shopping!
        </p>
      </div>
      <MainFooter />
    </>
  );
};

export default AboutUs;
