// src/Cart.js
import React, { useState, useEffect } from "react";
import "../css/payment.css";
import Loader from "../Components/Loader";

const Payment = ({
  cartObj,
  finalCartObj,
  formData,
  onUpdateFinalCart,
  isLoggedIn,
  appLoading,
}) => {
  // State to track selected payment method
  console.log(formData, "formData");
  console.log(appLoading, "appLoading_payment");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("online");

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
    onUpdateFinalCart(method);
  };

  const [finalCart, setFinalCart] = useState([]);

  // Update cart state when cartObj changes
  useEffect(() => {
    if (finalCartObj) {
      setFinalCart(finalCartObj);
    }
  }, [finalCartObj]); // Only run when cartObj changes

  // Update cart state when cartObj changes
  useEffect(() => {
    console.log(finalCartObj, "finalCartObj Payment");
  }, [finalCartObj]); // Only run when cartObj changes

  if (appLoading) {
    return <Loader appLoading={appLoading} />; // Show loader while loading
  }

  return (
    <>
      <div className="cart-main container">
        <div className="row price-cards-wrapper payment-row">
          <div className="col-lg-6 col-md-6 col-sm-12 p-0 mb-3">
            <div className="payment-col-1">
              <div class="checkout-card">
                <div class="checkout-title">Billing Details</div>
                <div class="info-item">
                  <i class="bi bi-person-circle"></i>
                  <p class="info-label">Full Name:</p>
                  <p className="info-value">
                    {formData?.fullName &&
                      formData.fullName.charAt(0).toUpperCase() +
                        formData.fullName.slice(1).toLowerCase()}
                  </p>
                </div>
                <div class="info-item">
                  <i class="bi bi-telephone-fill"></i>
                  <p class="info-label">Phone Number:</p>
                  <p class="info-value">+91 {formData?.primaryPhone}</p>
                </div>
                <div class="info-item">
                  <i class="bi bi-house-fill"></i>
                  <p class="info-label">Address:</p>
                  <p class="info-value">{formData?.address}</p>
                </div>
                <div class="info-item">
                  <i class="bi bi-geo-alt-fill"></i>
                  <p class="info-label">Landmark:</p>
                  <p class="info-value">{formData?.landmark}</p>
                </div>
                <div class="info-item">
                  <i class="bi bi-pin-map-fill"></i>
                  <p class="info-label">Pincode:</p>
                  <p class="info-value">{formData?.pincode}</p>
                </div>
                <div class="info-item">
                  <i class="bi bi-geo-alt-fill"></i>
                  <p class="info-label">City:</p>
                  <p class="info-value">{formData?.city}</p>
                </div>
                <div class="info-item">
                  <i class="bi bi-flag-fill"></i>
                  <p class="info-label">State:</p>
                  <p class="info-value">{formData?.state}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 p-0 ">
            <div className="payment-col-1 ">
              <div className="payment-details-card">
                <div className="card-title">
                  Price details ({finalCart?.items} Items)
                </div>
                <div className="price-spliter-section">
                  <div className="price-splitter">
                    <div className="spliter-title">
                      Total MRP (Inc. of Taxes)
                    </div>
                    <div className="spliter-value">₹{finalCart?.totalMRP}</div>
                  </div>
                  <div className="price-splitter">
                    <div className="spliter-title">Discount</div>
                    <div className="spliter-value">
                      - ₹{finalCart?.totalDiscount}
                    </div>
                  </div>
                  <div className="price-splitter">
                    <div className="spliter-title shipping-title">Shipping</div>
                    <div className="spliter-value ">
                      <span className="previous-price">
                        ₹{finalCart?.shipping}
                      </span>
                      <span className="shipping-value shipping-title">
                        Free
                      </span>{" "}
                    </div>
                  </div>

                  <div className="price-splitter">
                    <div className="spliter-title">Cart Total</div>
                    <div className="spliter-value">
                      ₹{finalCart?.totalMRP - finalCart?.totalDiscount}
                    </div>
                  </div>

                  {finalCart?.deliveryCharge > 0 && (
                    <>
                      <div className="price-splitter">
                        <div className="spliter-title">Cod Charges</div>
                        <div className="spliter-value">
                          ₹{finalCart?.deliveryCharge}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className=" total-splitter">
                  <div className="total-title">Total Amount</div>
                  <div className="total-title">₹{finalCart?.totalPrice}</div>
                </div>
              </div>
            </div>
            <div className="payment-col-1 mt-3">
              <div
                className="cod-box"
                onClick={() => handlePaymentMethodChange("online")} // Call function when the whole box is clicked
              >
                <div className="checkbox">
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedPaymentMethod === "online"}
                      onChange={(e) => {
                        e.stopPropagation(); // Prevent the click from bubbling up to the parent div
                        handlePaymentMethodChange("online");
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="cod-content">
                  <div className="pay-title">Pay Online</div>
                  <div className="pay-info">
                    Use Razorpay for secure online payments and avoid cash
                    handling charges.
                  </div>
                </div>
              </div>

              <div
                className="cod-box mt-3 mb-3"
                onClick={() => handlePaymentMethodChange("cod")}
              >
                <div className="checkbox">
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedPaymentMethod === "cod"}
                      onChange={(e) => {
                        e.stopPropagation(); // Prevent the click from bubbling up to the parent div
                        handlePaymentMethodChange("cod");
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="cod-content">
                  <div className="pay-title">Cash on Delivery</div>
                  <div className="pay-info">
                    Pay online to eliminate cash handling fees (₹50 per
                    product).
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
