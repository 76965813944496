// src/components/ProductView.js
import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../css/LandingPage.css";

const Header = ({ from, cartObj, finalCartObj, favorites }) => {
  const navigate = useNavigate();
  console.log(from, "from");
  console.log(cartObj, "cartObjHeader");
  console.log(favorites, "favoritesHeader");
  const handleLogoClick = () => {
    navigate("/");
  };
  const handleIcons = (event) => {
    if (event == "cart") {
      navigate("/checkout");
    } else if (event == "favorites") {
      navigate("/favorites");
    } else if (event == "profile") {
      navigate("/profile");
    }
  };
  const myLogo = require("../assets/images/icons/GlobalLogo.png");

  return (
    <>
      <div className="header-main">
        <div className="container">
          <a onClick={handleLogoClick} className="header-logo">
            <img src={myLogo} alt="Global India Shop" width="120" height="36" />
          </a>
          {/* <div className="header-search-container">
            <input
              type="search"
              name="search"
              className="search-field"
              placeholder="Enter your product name..."
            />

            <button className="search-btn">
              <ion-icon name="search-outline"></ion-icon>
            </button>
          </div> */}

          {/* {from == "checkout" && (
            <>
              <div className="page-title">Checkout Order</div>
            </>
          )} */}

          <div className="header-user-actions">
            <button
              className="action-btn"
              onClick={() => {
                handleIcons("profile");
              }}
            >
              <ion-icon name="person-outline"></ion-icon>
            </button>

            <button
              className="action-btn"
              onClick={() => {
                handleIcons("favorites");
              }}
            >
              <ion-icon name="heart-outline"></ion-icon>
              <span className="count">{favorites?.length}</span>
            </button>

            <button
              className="action-btn"
              onClick={() => {
                handleIcons("cart");
              }}
            >
              <ion-icon name="bag-handle-outline"></ion-icon>
              <span className="count">{cartObj?.length}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
