import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import {
  Visibility as EyeIcon,
  VisibilityOff as SlashedEyeIcon,
} from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateProduct } from "./productService";
const ProductItem = ({
  product,
  index,
  moveProduct,
  handleOpenImageModal,
  handleOpenDescriptionModal,
  handleEdit,
  handleDeleteProduct,
  handleStatusToggle,
}) => {
  const [, ref] = useDrag({
    type: "PRODUCT",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "PRODUCT",
    hover(item) {
      if (item.index !== index) {
        moveProduct(item.index, index); // Move product logic
        item.index = index; // Update the dragged item's index
      }
    },
  });

  const [loading, setLoading] = useState(false);

  return (
    // <tr >
    //   <td>{product.name}</td>
    //   <td>{product.price}</td>
    //   {/* Other product fields */}
    // </tr>

    <TableRow key={product._id} ref={(node) => ref(drop(node))}>
      <TableCell>
        <img
          src={`${product.main_image.replace(/\\/g, "/")}`} // Correct the URL
          alt={product.main_image}
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
          }}
        />
      </TableCell>
      <TableCell>{product.name}</TableCell>
      <TableCell>{product.type}</TableCell>
      <TableCell>{product.price}</TableCell>
      <TableCell>{product.before_price}</TableCell>
      <TableCell>{product.discount}%</TableCell>
      <TableCell>{product.sizes.join(", ")}</TableCell>
      <TableCell>
        {product.colors.map((color) => (
          <span
            key={color}
            style={{
              backgroundColor: color,
              padding: "5px",
              marginRight: "5px",
              borderRadius: "4px",
              display: "inline-block",
              width: "20px",
              height: "20px",
            }}
          ></span>
        ))}
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() => handleOpenImageModal(product.imageList)}
          aria-label="View Images"
        >
          <ImageIcon />
        </IconButton>

        <IconButton
          onClick={() =>
            handleOpenDescriptionModal(
              product.specifications,
              product.features,
              product.moreInfo
            )
          }
          aria-label="View Descriptions"
        >
          <DescriptionIcon />
        </IconButton>

        <IconButton onClick={() => handleEdit(product)} aria-label="Edit">
          <EditIcon />
        </IconButton>

        <IconButton
          onClick={() => handleDeleteProduct(product._id)}
          aria-label="Delete"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        <IconButton
          aria-label={product.status === "active" ? "Deactivate" : "Activate"}
          onClick={() => handleStatusToggle(product)}
          disabled={loading} // Disable button while loading
        >
          {product.status === "active" ? <EyeIcon /> : <SlashedEyeIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ProductItem;
