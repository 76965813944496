import React, { useEffect, useState } from "react";
import "../css/trustcard.css";

function TrustCards({ from }) {
  return (
    <>
      {from == "product" && (
        <>
          <div className="trustcards">
            <div className="trustcard-wrapper">
              <div>
                <i className="trust-icons bi bi-truck"></i>
              </div>
              <div className="trustcard-text">Free Delivery</div>
            </div>
            <span className="separator"></span>
            <div className="trustcard-wrapper">
              <div>
                <i className="trust-icons bi bi-arrow-clockwise"></i>
              </div>
              <div className="trustcard-text">7 Days Returns</div>
            </div>
            <span className="separator"></span>
            <div className="trustcard-wrapper">
              <div>
                <i className="trust-icons bi bi-cash"></i>
              </div>
              <div className="trustcard-text">Cash On Delivery </div>
            </div>
            {/* <div className="trustcard-wrapper">
              <div>
                <i className="trust-icons bi bi-trophy"></i>
              </div>
              <div className="trustcard-text">Trusted Brand</div>
            </div>
            <div className="trustcard-wrapper">
              <div>
                <i className="trust-icons bi bi-check-circle-fill"></i>
              </div>
              <div className="trustcard-text">
                Verified By Global India Shop
              </div>
            </div> */}
          </div>
        </>
      )}

      {from == "landing" && (
        <>
          <div className="container my-4 p-0">
            <div className="trust-cards-main">
              <div className="mb-3 d-flex justify-content-center align-items-center">
                <div className="icon-box-trust">
                  <i
                    className="bi bi-emoji-smile"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p className=" icon-box-trust-text">Happy Indian Customers</p>
                </div>
              </div>
              <div className=" mb-3 d-flex justify-content-center align-items-center">
                <div className="icon-box-trust">
                  <i
                    className="bi bi-shield-check"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p className=" icon-box-trust-text">
                    Made in{" "}
                    <span style={{ fontFamily: "sans-serif" }}>india</span>
                  </p>
                </div>
              </div>
              <div className=" mb-3 d-flex justify-content-center align-items-center">
                <div className="icon-box-trust">
                  <i
                    className="bi bi-box-arrow-right"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p className=" icon-box-trust-text">15 Days Easy Return</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {from == "product-row-2" && (
        <>
          <div className="container my-4 p-0">
            <div className="trust-cards-main">
              <div className=" mb-3 d-flex justify-content-center align-items-center">
                <div className="icon-box-trust">
                  <i className="bi bi-globe" style={{ fontSize: "2rem" }}></i>
                  <p className=" icon-box-trust-text">
                    Indian{" "}
                    <span style={{ fontFamily: "sans-serif" }}>Brand</span>
                  </p>
                </div>
              </div>

              <div className="mb-3 d-flex justify-content-center align-items-center">
                <div className="icon-box-trust">
                  <i
                    className="bi bi-emoji-smile"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p className=" icon-box-trust-text">Happy Indian Customers</p>
                </div>
              </div>

              <div className=" mb-3 d-flex justify-content-center align-items-center">
                <div className="icon-box-trust">
                  <i
                    className="bi bi-box2-heart-fill"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p className=" icon-box-trust-text">Packed with safety</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {from == "cart-page" && (
        <>
          <div className="container my-4 p-0">
            <div className="trust-cards-main">
              <div className=" mb-3 d-flex justify-content-center align-items-center">
                <div className="icon-box-trust">
                  <i className="bi bi-globe" style={{ fontSize: "2rem" }}></i>
                  <p className=" icon-box-trust-text">
                    Indian{" "}
                    <span style={{ fontFamily: "sans-serif" }}>Brand</span>
                  </p>
                </div>
              </div>

              <div className="mb-3 d-flex justify-content-center align-items-center">
                <div className="icon-box-trust">
                  <i
                    className="bi bi-emoji-smile"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p className=" icon-box-trust-text">1M+ Indian Customers</p>
                </div>
              </div>

              <div className=" mb-3 d-flex justify-content-center align-items-center">
                <div className="icon-box-trust">
                  <i
                    className="bi bi-box2-heart-fill"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p className=" icon-box-trust-text">Packed with safety</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TrustCards;
