import React, { useEffect, useState, useRef } from "react";
import "../css/LandingPage.css";
import { Collapse } from "@mui/material";
import HeroCarousel from "../Components/HeroCarousel";
import SwiperSlider from "../Components/SwiperSlider";
import ResponsiveTabs from "../Components/ResponsiveTabs";
import ProductsRow from "../Components/ProductsRow";
import TrustCards from "./TrustCards";
import EmailPage from "./EmailPage";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createCategory,
  getAllCategories,
  updateCategory,
  deleteCategory,
} from "../admin/Categories/services/categoryService";
import {
  createProduct,
  getProductsByCategory,
  updateProduct,
  deleteProduct,
} from "../admin/Products/productService";
import Loader from "../Components/Loader";
const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Determine the category based on the URL path
  const currentCategory = location.pathname.replace("/", "");

  const electronicBanner1 = require("../assets/images/electronics-banner-1.jpg");
  const mensBanner = require("../assets/images/mens-banner.jpg");
  const mobile_banner_2 = require("../assets/images/Banners/mobile_banner_2.png");
  const category_1 = require("../assets/images/Banners/category_1.png");
  const category_2 = require("../assets/images/Banners/category_2.png");
  const category_3 = require("../assets/images/Banners/category_3.png");
  const category_4 = require("../assets/images/Banners/category_4.png");
  const combo_banner_mobile = require("../assets/images/Banners/combo_banner_mobile.png");
  const combo_banner_desktop = require("../assets/images/Banners/combo_banner_desktop.png");
  const womensBanner = require("../assets/images/womens-banner.jpg");
  const electronicsBanner2 = require("../assets/images/electronics-banner-2.jpg");
  const paymentIcon = require("../assets/images/payment.png");

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, []);

  const [productTypes, setProductTypes] = useState([
    {
      name: "Kurtis",
      id: 1,
      image: category_4,
    },
    {
      name: "Clothing",
      id: 2,
      image: category_1,
    },
    {
      name: "Watches",
      id: 3,
      image: category_2,
    },
    // {
    //   name: "Home",
    //   id: 3,
    //   image: category_3,
    // },
  ]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(currentCategory);
  const [products, setProducts] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const fetchCategories = async () => {
    try {
      const response = await getAllCategories();
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const fetchProducts = async () => {
    setLoading(true); // Start loading
    try {
      const response = await getProductsByCategory(
        "671632c552d1ad2675d3f4a8",
        "user"
      );
      setProducts(response.data);
      setDiscountList(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    console.log(categories, "categories");
  }, [categories]);

  useEffect(() => {
    console.log(products, "products");
  }, [products]);

  const [titleOne, setTitleOne] = useState("");
  const [tabOneLabels, setTabOneLabels] = useState([]);
  const [tabTwoLabels, setTabTwoLabels] = useState([]);

  useEffect(() => {
    if (category == "watches" || category == "") {
      setTitleOne("Watches");
      setTabOneLabels(["Mens", "Womens", "Couples Watches"]);
      setTabTwoLabels(["Womens", "Couples Watches"]);
    } else if (category == "mens") {
      setTitleOne("T Shirts");
      setTabOneLabels(["polo", "plain"]);
      setTabTwoLabels(["polo", "plain"]);
    }
    console.log(category, "category");
  }, [category]);

  const tabLabels_2 = ["Pick any 4", "Pick any 3", "Pick any 2"];
  const currentMonth = new Date().toLocaleString("default", { month: "long" });

  const goTo = (event) => {
    if (event == "about") {
      navigate(`/about-global-india-shop`);
    } else if (event == "contact") {
      navigate(`/contact-us`);
    } else if (event == "terms") {
      navigate(`/terms-conditions`);
    }
  };
  const SOCIAL_MEDIA_LINKS = {
    youtube: "https://youtube.com/@globalindiashop?si=C_XDNLErd_1dksuu", // Replace with your YouTube channel URL
    facebook: "https://www.facebook.com/your-page", // Replace with your Facebook page URL
    twitter: "https://x.com/GlobalIndiaShop", // Replace with your Twitter profile URL
    instagram:
      "https://www.instagram.com/global_india_shop?igsh=MTE0N3ExYjJ5NXAzMA==", // Replace with your Instagram profile URL
  };

  const mensRef = useRef(null);
  const womensRef = useRef(null);
  const couplesRef = useRef(null);

  const typeClick = (type) => {
    if (type?.id === 1) {
      navigate("/home/kurtis");
    } else if (type?.id === 2) {
      navigate("/home/clothing ");
    } else if (type?.id === 3) {
      navigate("/home/watches");
    }
    // else if (type?.id === 3) {
    //   navigate("/home/home-products");
    // }
  };

  if (loading) {
    return <Loader loading={loading} />; // Show loader while loading
  }

  return (
    <>
      {/* <div className="overlay" data-overlay></div> */}
      <header>
        <nav className="desktop-navigation-menu">
          <div className="container">
            <ul className="desktop-menu-category-list">
              <li className="menu-category">
                <a href="#" className="menu-title">
                  Home
                </a>
              </li>

              <li className="menu-category">
                <a href="#" className="menu-title">
                  Men's
                </a>

                <div className="dropdown-panel">
                  <ul className="dropdown-panel-list">
                    <li className="menu-title">
                      <a href="#">Topwear</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Printed T-Shirts</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Oversize T-Shirts New</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Plain T-Shirts</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Full Sleeve T-Shirts</a>
                    </li>
                    <li className="panel-list-item">
                      <a href="#">Polo T-Shirts</a>
                    </li>
                    <li className="panel-list-item">
                      <a href="#">Activewear</a>
                    </li>
                    <li className="panel-list-item">
                      <a href="#">Half Sleeve T-Shirts</a>
                    </li>
                    <li className="panel-list-item">
                      <a href="#">Plus Size T-Shirts</a>
                    </li>
                    <li className="panel-list-item">
                      <a href="#">Combos</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">
                        <img
                          src={electronicBanner1}
                          alt="headphone collection"
                          width="250"
                          height="119"
                        />
                      </a>
                    </li>
                  </ul>
                  <ul className="dropdown-panel-list">
                    <li className="menu-title">
                      <a href="#">Bottomwear</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Joggers</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Chino Pants SAVE 250</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Boxers</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Shorts</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Cargo Pants New</a>
                    </li>
                    <li className="panel-list-item">
                      <a href="#">Couple Boxers</a>
                    </li>
                    <li className="panel-list-item">
                      <a href="#">Pyjamas</a>
                    </li>
                    <li className="panel-list-item">
                      <a href="#">Jeans New</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">
                        <img
                          src={mensBanner}
                          alt="men's fashion"
                          width="250"
                          height="119"
                        />
                      </a>
                    </li>
                  </ul>

                  <ul className="dropdown-panel-list">
                    <li className="menu-title">
                      <a href="#">Shirts</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Regular Shirts </a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Casual Shirts </a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#"> Satin shirts</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">
                        <img
                          src={womensBanner}
                          alt="women's fashion"
                          width="250"
                          height="119"
                        />
                      </a>
                    </li>
                  </ul>
                  <ul className="dropdown-panel-list">
                    <li className="menu-title">
                      <a href="#">Special</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Couple T-shirts</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Bestseller T shirts</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">Deals & Offers</a>
                    </li>

                    <li className="panel-list-item">
                      <a href="#">
                        <img
                          src={electronicsBanner2}
                          alt="mouse collection"
                          width="250"
                          height="119"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-category">
                <a href="#" className="menu-title">
                  Women's
                </a>

                <ul className="dropdown-list">
                  <li className="dropdown-item">
                    <a href="#">Boxer for Women</a>
                  </li>

                  <li className="dropdown-item">
                    <a href="#">Jeggings</a>
                  </li>

                  <li className="dropdown-item">
                    <a href="#">Women Pants</a>
                  </li>
                  <li className="dropdown-item">
                    <a href="#">Couple Boxers</a>
                  </li>
                  <li className="dropdown-item">
                    <a href="#">Couple T-shirts</a>
                  </li>
                  <li className="dropdown-item">
                    <a href="#">Bestseller T shirts</a>
                  </li>

                  <li className="dropdown-item">
                    <a href="#">Deals and Offers</a>
                  </li>
                </ul>
              </li>

              <li className="menu-category">
                <a href="#" className="menu-title">
                  Perfume
                </a>

                <ul className="dropdown-list">
                  <li className="dropdown-item">
                    <a href="#">Clothes Perfume</a>
                  </li>

                  <li className="dropdown-item">
                    <a href="#">Deodorant</a>
                  </li>

                  <li className="dropdown-item">
                    <a href="#">Flower Fragrance</a>
                  </li>

                  <li className="dropdown-item">
                    <a href="#">Air Freshener</a>
                  </li>
                </ul>
              </li>

              <li className="menu-category">
                <a href="#" className="menu-title">
                  Blog
                </a>
              </li>

              <li className="menu-category">
                <a href="#" className="menu-title">
                  Hot Offers
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <main>
        {
          <div className="category-section mt-4">
            <div className="container">
              <div className="category-column">
                {productTypes.map((type) => (
                  <div
                    className="category-items"
                    key={type.id}
                    onClick={() => {
                      typeClick(type);
                    }}
                  >
                    <img
                      src={type.image}
                      alt={type.name}
                      className="category-icon"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      </main>
      <footer>
        <div className="footer-nav">
          <div className="container">
            {/* <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">Popular Categories</h2>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Fashion
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Electronic
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Cosmetic
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Health
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Watches
                </a>
              </li>
            </ul> */}

            {/* <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">Need Help</h2>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Track Order
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Return & refund
                </a>
              </li>

              <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  FAQs
                </a>
              </li>
            </ul> */}

            <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">Company</h2>
              </li>

              <li className="footer-nav-item">
                <a onClick={() => goTo("about")} className="footer-nav-link">
                  About Us
                </a>
              </li>

              <li className="footer-nav-item">
                <a onClick={() => goTo("contact")} className="footer-nav-link">
                  Contact Us
                </a>
              </li>

              {/* <li className="footer-nav-item">
                <a href="#" className="footer-nav-link">
                  Privacy Policy
                </a>
              </li> */}

              <li className="footer-nav-item">
                <a onClick={() => goTo("terms")} className="footer-nav-link">
                  Terms and conditions
                </a>
              </li>
            </ul>

            <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">Contact</h2>
              </li>

              <li className="footer-nav-item flex">
                <div className="icon-box">
                  <ion-icon name="location-outline"></ion-icon>
                </div>
                <address className="content">
                  120D, St Marys Street, Chennai, India
                </address>
              </li>

              <li className="footer-nav-item flex">
                <div className="icon-box">
                  <ion-icon name="call-outline"></ion-icon>
                </div>

                <a href="tel:+607936-8058" className="footer-nav-link">
                  (+91) 79041 39207
                </a>
              </li>

              <li className="footer-nav-item flex">
                <div className="icon-box">
                  <ion-icon name="mail-outline"></ion-icon>
                </div>

                <a href="mailto:example@gmail.com" className="footer-nav-link">
                  contact.globalindiashop@gmail.com
                </a>
              </li>
            </ul>

            <ul className="footer-nav-list">
              <li className="footer-nav-item">
                <h2 className="nav-title">Follow Us</h2>
              </li>

              <li>
                <ul className="social-link">
                  <li className="footer-nav-item">
                    <a
                      href={SOCIAL_MEDIA_LINKS.youtube}
                      className="footer-nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ion-icon name="logo-youtube"></ion-icon>
                    </a>
                  </li>

                  <li className="footer-nav-item">
                    <a
                      href={SOCIAL_MEDIA_LINKS.twitter}
                      className="footer-nav-link"
                    >
                      <ion-icon name="logo-twitter"></ion-icon>
                    </a>
                  </li>

                  <li className="footer-nav-item">
                    <a
                      href={SOCIAL_MEDIA_LINKS.instagram}
                      className="footer-nav-link"
                    >
                      <ion-icon name="logo-instagram"></ion-icon>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <img
              src={paymentIcon}
              alt="payment method"
              className="payment-img"
            />

            <p className="copyright">
              Copyright &copy; <a href="#">Global India Shop</a> all rights
              reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Home;

{
  /* <div className="category-section mt-4">
          <div className="container">
            <div className="category-column">
              {categories.map((category) => (
                <div className="category-items" key={category.id}>
                  <img
                    src={category.icon}
                    alt={category.name}
                    className="category-icon"
                  />
                  <div className="category-name">{category.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div> */
}
