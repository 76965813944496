// src/TermsAndConditions.js
import React, { useEffect } from "react";
import "../css/TermsAndConditions.css"; // Import custom CSS
import MainFooter from "../Components/MainFooter";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="terms-container">
        <h5>Terms and Conditions</h5>
        <div className="terms-content">
          <h6 className="terms-subhead">1. Introduction</h6>
          <p>
            Welcome to Global India Shop. These Terms and Conditions govern your
            use of our website. By accessing or using our website, you agree to
            be bound by these Terms.
          </p>

          <h6 className="terms-subhead">2. Use of the Website</h6>
          <p>
            You may use our website for lawful purposes only. You agree not to
            use the website in any way that could damage, disable, or impair it.
          </p>

          <h6 className="terms-subhead">3. Intellectual Property</h6>
          <p>
            All content on our website, including text, images, and logos, is
            the property of Global India Shop and is protected by intellectual
            property laws.
          </p>

          <h6 className="terms-subhead">4. Limitation of Liability</h6>
          <p>
            We are not liable for any direct, indirect, incidental, or
            consequential damages arising from the use of our website or
            inability to use it.
          </p>

          <h6 className="terms-subhead">5. Changes to the Terms</h6>
          <p>
            We may update these Terms and Conditions from time to time. Your
            continued use of the website constitutes acceptance of the new
            Terms.
          </p>

          <h6 className="terms-subhead">6. Contact Us</h6>
          <p className="mb-5">
            If you have any questions about these Terms and Conditions, please
            contact us at{" "}
            <a href="mailto:support@globalindiashop.com">
              contact.globalindiashop@gmail.com
            </a>
          </p>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default TermsAndConditions;
