// src/components/AddCategory.js
import React, { useState, useEffect } from "react";
import {
  createCategory,
  getAllCategories,
  updateCategory,
  deleteCategory,
} from "../services/categoryService";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
const AddCategory = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [message, setMessage] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    console.log(imagePreview, "imagePreview");
  }, [imagePreview]);

  const fetchCategories = async () => {
    try {
      const response = await getAllCategories();
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    if (image) formData.append("image", image);

    try {
      if (editMode) {
        await updateCategory(currentCategoryId, formData);
        toast.success("Category updated successfully");
      } else {
        await createCategory(formData);
        toast.success("Category added successfully");
      }
      resetForm();
      fetchCategories();
    } catch (error) {
      toast.error("Error processing request");
    }
  };

  const resetForm = () => {
    setName("");
    setDescription("");
    setImage(null);
    setImagePreview(null);
    setEditMode(false);
    setCurrentCategoryId(null);
  };

  const handleEdit = (category) => {
    setName(category.name);
    setDescription(category.description);
    setCurrentCategoryId(category._id);
    setEditMode(true);
    setImagePreview(`${category.image}`);
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        await deleteCategory(id);
        fetchCategories();
        toast.success("category Deleted!", {
          position: "top-center",
          autoClose: 2000,
        });
      } catch (error) {
        toast.error("Error deleting category", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } else {
      toast.info("Delete action canceled.", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const isBase64 = (string) => {
    return string.startsWith("data:image/");
  };

  const handleCategoryClick = (categoryId) => {
    navigate(`/admin-dashboard/categories/${categoryId}`);
  };

  return (
    <div className="container-fluid mt-5" style={{ fontFamily: "sans-serif" }}>
      <div className="row">
        <div className="col-lg-4 mb-4">
          <h6 className="mb-3">Existing Categories</h6>
          <div className="row">
            {categories.map((category) => (
              <div
                className="col-12 mb-3"
                style={{ cursor: "pointer" }}
                key={category._id}
              >
                <div className="card h-100 shadow-sm">
                  <div className="row g-0">
                    <div className="col-md-4">
                      <img
                        src={`${category.image.replace(/\\/g, "/")}`} // Correct the URL
                        className="img-fluid rounded-start category-card-image"
                        alt={category.name}
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body p-2 d-flex justify-content-between align-items-center">
                        <div>
                          <h5
                            className="card-title mb-1"
                            onClick={() => handleCategoryClick(category._id)}
                          >
                            {category.name}
                          </h5>
                          <p className="card-text small mb-1">
                            {category.description}
                          </p>
                        </div>
                        <div>
                          <i
                            className="bi bi-pencil-square mx-2"
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() => handleEdit(category)}
                          ></i>
                          <i
                            className="bi bi-trash mx-2"
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => handleDelete(category._id)}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-lg-8">
          <h6 className="mb-3">
            {editMode ? "Edit Category" : "Add New Category"}
          </h6>

          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <label htmlFor="name" className="form-label small">
                Category Name
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="mb-2">
              <label htmlFor="description" className="form-label small">
                Description
              </label>
              <textarea
                className="form-control form-control-sm"
                id="description"
                rows="2"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              ></textarea>
            </div>

            <div className="mb-2">
              <label htmlFor="image" className="form-label small">
                Category Image
              </label>
              <input
                type="file"
                className="form-control form-control-sm"
                id="image"
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>

            {imagePreview && (
              <div className="mb-2">
                <label className="form-label small">Image Preview:</label>
                <img
                  src={
                    isBase64(imagePreview) ? imagePreview : `${imagePreview}`
                  }
                  alt="Preview"
                  className="img-fluid"
                  style={{ maxHeight: "200px" }}
                />
              </div>
            )}

            <button type="submit" className="btn btn-sm btn-primary">
              {editMode ? "Update Category" : "Add Category"}
            </button>
            {editMode && (
              <button
                type="button"
                className="btn btn-sm btn-secondary mx-2"
                onClick={resetForm}
              >
                Cancel
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
