// src/services/visitorService.js
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/visitor`;

// Function to record a visit
export const recordVisit = () => {
  return axios.post(`${BASE_URL}/recordVisit`);
};

// Function to get visitor data within a specified date range
export const getVisitorData = (startDate, endDate) => {
  return axios.get(`${BASE_URL}/getVisitorData`, {
    params: { startDate, endDate },
  });
};
