// src/Cart.js
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../css/address.css";
import Snackbar from "@mui/material/Snackbar";
import MainFooter from "../Components/MainFooter";
import Swal from "sweetalert2";
import { Autocomplete } from "@mui/lab";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  OutlinedInput,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import LoginSignupDialog from "../Components/LoginSignupDialog";
import { deleteUserAddress } from "../admin/Categories/services/authService";
const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

const Address = forwardRef(
  (
    {
      setAddressObject,
      cartObj,
      finalCartObj,
      handleChange,
      formData,
      errors,
      setErrors,
      currentUser,
      setNewUser,
      isLoggedIn,
      setFormData,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedProduct = location.state && location.state.chosedProduct;
    console.log(location, "selectedProduct");
    const [quantity, setQuantity] = useState(1);
    const [totalPrice, setTotalPrice] = useState(null);
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const [cart, setCart] = useState();
    const [finalCart, setFinalCart] = useState([]);

    // Update cart state when cartObj changes
    useEffect(() => {
      if (cartObj) {
        setCart(cartObj);
        setFinalCart(finalCartObj);
      }
    }, [cartObj, finalCartObj]); // Only run when cartObj changes

    useEffect(() => {
      setTotalPrice(quantity * selectedProduct?.price);
    }, [quantity, selectedProduct?.price]);

    const handleClose = () => {
      setLoaderOpen(false);
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [navigate]);

    // const [formData, setFormData] = useState({
    //   fullName: "",
    //   primaryPhone: "",
    //   address: "",
    //   landmark: "",
    //   pincode: "",
    //   city: "",
    //   state: "",
    //   state: states[0],
    //   quantity: quantity,
    //   color: selectedProduct?.selectedColor,
    // });

    // Handle input change
    // const handleChange = (e) => {
    //   const { name, value } = e.target;
    //   let updatedValue = value;
    //   // Restrict input to digits only for phone and pincode fields
    //   if (name === "primaryPhone") {
    //     updatedValue = value.replace(/\D/g, "").slice(0, 10); // Allow only 10 digits
    //   } else if (name === "pincode") {
    //     updatedValue = value.replace(/\D/g, "").slice(0, 6); // Allow only 6 digits
    //   }

    //   // Update form data
    //   setFormData((prevState) => ({
    //     ...prevState,
    //     [name]: updatedValue,
    //   }));

    //   // Clear errors as soon as user starts typing
    //   if (errors[name] && updatedValue) {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       [name]: "",
    //     }));
    //   }
    //   console.log(formData, "formData");
    // };

    // Validate fields
    const validateFields = () => {
      // alert("validateFields");
      const newErrors = {};
      if (!formData.fullName) newErrors.fullName = "Full Name is required";
      if (!formData.primaryPhone)
        newErrors.primaryPhone = "Primary Phone Number is required";
      if (!formData.address) newErrors.address = "Complete Address is required";
      if (!formData.landmark) newErrors.landmark = "Landmark is required";
      if (!formData.pincode) newErrors.pincode = "Pincode is required";
      if (!formData.city) newErrors.city = "City is required";
      if (!formData.state) newErrors.state = "State is required";
      // Validate numbers
      if (formData.primaryPhone && !/^\d{10}$/.test(formData.primaryPhone))
        newErrors.primaryPhone =
          "Primary Phone Number must be exactly 10 digits";
      if (formData.pincode && !/^\d{6}$/.test(formData.pincode))
        newErrors.pincode = "Pincode must be exactly 6 digits";
      if (formData.state == "Select State") {
        newErrors.state = "Please Select State";
      }
      setErrors(newErrors);
      // return Object.keys(newErrors).length === 0;
      return (
        Object.keys(newErrors).length === 0 ||
        (selectedAddress && selectedAddress.id)
      );
    };

    // Expose the function using useImperativeHandle
    useImperativeHandle(ref, () => ({
      validateFields, // Expose this function to parent component
    }));

    // Handle form submit
    const handleSubmit = async () => {
      if (validateFields()) {
        try {
          setLoading(true);
          setLoaderOpen(true);
          // Console the form data
          console.log(formData, "formData");
          // Example API endpoint
          const response = await fetch(
            "https://api.globalindiashop.com/users",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );
          const result = await response.json();
          if (result?.status === true) {
            setLoading(false);
            setLoaderOpen(false);
            setOpenSnackbar(true); // Show Snackbar
            setTimeout(() => {
              setOpenSnackbar(false); // Hide Snackbar
              navigate("/ordered-successfully");
            }, 2000);
          }
          console.log("API Response:", result);
          // Handle success (e.g., show a success message or close the modal)
        } catch (error) {
          console.error("Error:", error);
          setLoading(false);
          setLoaderOpen(false);
          // Handle error (e.g., show an error message)
        }
      }
    };

    const handleIncrease = () => {
      setQuantity(quantity + 1);
    };

    const handleDecrease = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    };

    const handleQuanitiyChange = (event) => {
      const value = event.target.value;
      if (value === "" || /^\d+$/.test(value)) {
        setQuantity(Number(value));
      }
    };
    const arrivingDate = new Date();
    arrivingDate.setDate(arrivingDate.getDate() + 5);
    const formattedDate = arrivingDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    useEffect(() => {
      console.log(formData, "formData");
      setAddressObject(formData);
    }, [formData]);

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = () => {
      setDialogOpen(true);
    };

    const handleCloseDialog = () => {
      setDialogOpen(false);
      setNewUser();
    };

    console.log(isLoggedIn, "isLoggedIn");

    const handleEdit = (address) => {
      console.log("Edit Address:", address);
      setIsFormVisible(true);

      // Handle address edit logic
      setFormData({
        id: address.id,
        fullName: address.fullName,
        primaryPhone: address.primaryPhone,
        address: address.address,
        landmark: address.landmark,
        pincode: address.pincode,
        city: address.city,
        state: address.state,
      });
    };

    const handleDelete = async (address) => {
      console.log("Delete Address:", address);
      // Handle address delete logic
      const result = await Swal.fire({
        title: "Confirm Deletion",
        text: "Are you sure you want to remove this address? This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        try {
          await deleteUserAddress(currentUser.id, address?.id);
          setNewUser();
          toast.success("Address Deleted!", {
            position: "top-center",
            autoClose: 2000,
          });
        } catch (error) {
          toast.error("Error deleting address", {
            position: "top-center",
            autoClose: 2000,
          });
        }
      } else {
        toast.info("Delete action canceled.", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    };

    // This useEffect will run whenever `selectedAddress` is updated
    useEffect(() => {
      console.log("Updated selectedAddress:", selectedAddress);
    }, [selectedAddress]);

    // Initialize with the first address in the array when the component mounts
    useEffect(() => {
      if (
        currentUser &&
        currentUser.addresses &&
        currentUser.addresses.length > 0
      ) {
        setSelectedAddress(currentUser.addresses[0].id); // Select the first address's id by default
        setFormData({
          id: currentUser.addresses[0].id,
          fullName: currentUser.addresses[0].fullName,
          primaryPhone: currentUser.addresses[0].primaryPhone,
          address: currentUser.addresses[0].address,
          landmark: currentUser.addresses[0].landmark,
          pincode: currentUser.addresses[0].pincode,
          city: currentUser.addresses[0].city,
          state: currentUser.addresses[0].state,
        });
      }
    }, [currentUser]);

    const handleSelect = (address) => {
      console.log("Selected Address ID:", address);
      setSelectedAddress(address.id); // This will trigger the useEffect
      setIsFormVisible(false);
      setFormData({
        id: address.id,
        fullName: address.fullName,
        primaryPhone: address.primaryPhone,
        address: address.address,
        landmark: address.landmark,
        pincode: address.pincode,
        city: address.city,
        state: address.state,
      });
    };

    // Function to toggle form visibility
    const handleAddButtonClick = () => {
      // setIsFormVisible((prev) => !prev);
      setFormData({
        fullName: "",
        primaryPhone: "",
        address: "",
        landmark: "",
        pincode: "",
        city: "",
        state: "",
      });
      setIsFormVisible(true);
      setSelectedAddress(null);
    };

    const handleAddressChange = () => {};

    return (
      <>
        <div className="cart-main container">
          <div className="row price-cards-wrapper">
            <div className="col-lg-6 col-md-6 col-sm-12 p-0 mr-3">
              <div className="address-cart-card">
                <div className="login-account-text">
                  {!isLoggedIn && (
                    <>
                      <div>
                        <span className="allready">Already have a account</span>
                        <span className="login-main" onClick={handleOpenDialog}>
                          Login / Signup
                        </span>
                      </div>
                      <span className="login-seprator">Or</span>
                      <span
                        className="guest"
                        style={{
                          color: "rgb(131, 131, 131)",
                          fontWeight: 600,
                          marginBottom: "0px",
                        }}
                      >
                        Checkout as Guest
                      </span>
                    </>
                  )}
                  {isLoggedIn && (
                    <>
                      <span
                        className="guest mt-2"
                        style={{
                          color: "rgb(131, 131, 131)",
                          fontWeight: 600,
                          marginBottom: "0px",
                        }}
                      >
                        Welcome back, {currentUser?.firstName}. Proceed with
                        your checkout.
                      </span>
                    </>
                  )}
                </div>
                <span className="border-bottom-shipping"></span>
                {isLoggedIn && (
                  <>
                    {currentUser?.addresses.length > 0 && (
                      <>
                        <span className="heading delivery-heading">
                          Your addresses
                        </span>
                        <div className="address-list">
                          {currentUser?.addresses.map((address) => (
                            <div
                              className="address-card"
                              onClick={() => handleSelect(address)} // Call function when the whole box is clicked
                            >
                              <div className="address-card-one">
                                <div className="checkbox">
                                  <label className="custom-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={selectedAddress === address?.id}
                                      onChange={(e) => {
                                        e.stopPropagation(); // Prevent the click from bubbling up to the parent div
                                        handleSelect(address);
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <div className="address-content">
                                  <span>{address.fullName}, </span>
                                  <span>{address.primaryPhone}, </span>
                                  <span>{address.landmark}, </span>
                                  <span>{address.address}, </span>
                                  <span>
                                    {address.city}, {address.state} -{" "}
                                    {address.pincode}
                                  </span>
                                </div>
                                <div className="address-actions">
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleEdit(address);
                                    }}
                                  >
                                    <i className="bi bi-pencil-square action-icons edit-action"></i>
                                  </div>
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDelete(address);
                                    }}
                                  >
                                    <i className="bi bi-trash2-fill action-icons"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="add-btn-section">
                          <div
                            className="add-button-icon"
                            onClick={handleAddButtonClick}
                          >
                            <i class="bi bi-plus-circle-fill"></i>
                            <div className="add-btn-text">Add New Address</div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                <>
                  {(isFormVisible ||
                    !isLoggedIn ||
                    currentUser?.addresses.length == 0) && (
                    <>
                      <div className="address-form mt-2">
                        <div>
                          <div className="modal-input-container  ">
                            <TextField
                              label="Primary Phone Number"
                              name="primaryPhone"
                              variant="outlined"
                              fullWidth
                              placeholder="Enter your phone number"
                              value={formData.primaryPhone}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <i className="bi bi-telephone-fill user-icon"></i>
                                  </InputAdornment>
                                ),
                                inputProps: {
                                  maxLength: 10, // Restrict input length to 10 digits
                                  onKeyDown: (e) => {
                                    if (
                                      e.key !== "Backspace" &&
                                      e.key !== "Delete" &&
                                      isNaN(Number(e.key))
                                    ) {
                                      e.preventDefault();
                                    }
                                  },
                                },
                              }}
                              className="modal-input"
                              error={!!errors.primaryPhone}
                              helperText={errors.primaryPhone}
                            />
                          </div>

                          <div className="modal-input-container  mr-2">
                            <TextField
                              label="Full Name"
                              name="fullName"
                              variant="outlined"
                              fullWidth
                              placeholder="Enter your full name"
                              value={formData.fullName}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <i className="bi bi-person-fill user-icon"></i>
                                  </InputAdornment>
                                ),
                              }}
                              className="modal-input"
                              error={!!errors.fullName}
                              helperText={errors.fullName}
                            />
                          </div>

                          <div className="modal-input-container">
                            <TextField
                              label="House, Apartment, Landmark, etc"
                              name="landmark"
                              placeholder="Nearby school, hospital, shop"
                              variant="outlined"
                              fullWidth
                              value={formData.landmark}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <i className="bi bi-crosshair2 user-icon"></i>
                                  </InputAdornment>
                                ),
                              }}
                              className="modal-input"
                              error={!!errors.landmark}
                              helperText={errors.landmark}
                            />
                          </div>

                          <div className="modal-input-container">
                            <TextField
                              label="Complete Address"
                              name="address"
                              variant="outlined"
                              fullWidth
                              placeholder="Enter your complete address"
                              value={formData.address}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <i className="bi bi-geo-alt-fill user-icon"></i>
                                  </InputAdornment>
                                ),
                              }}
                              className="modal-input"
                              error={!!errors.address}
                              helperText={errors.address}
                            />
                          </div>

                          <div className="modal-input-container">
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={!!errors.state}
                            >
                              <Autocomplete
                                options={states}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) =>
                                  handleChange({
                                    target: { name: "state", value },
                                  })
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="State"
                                    placeholder="Search and select your state"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <i className="bi bi-geo-alt-fill user-icon"></i>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                // Optional: You can add a freeSolo prop if you want users to input values not in the list
                                // freeSolo
                              />
                              {errors.state && (
                                <FormHelperText>{errors.state}</FormHelperText>
                              )}
                            </FormControl>
                          </div>

                          <div className="address-form-flex">
                            <div className="modal-input-container">
                              {/* <TextField
                                label="City"
                                name="city"
                                variant="outlined"
                                fullWidth
                                placeholder="Enter your city"
                                value={formData.city}
                                onChange={handleChange}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <i className="bi bi-geo-alt-fill user-icon"></i>
                                    </InputAdornment>
                                  ),
                                }}
                                className="modal-input"
                                error={!!errors.city}
                                helperText={errors.city}
                              /> */}

                              <TextField
                                label="City"
                                name="city"
                                variant="outlined"
                                fullWidth
                                placeholder="Enter city"
                                value={formData.city}
                                onChange={handleChange}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <i className="bi bi-geo-alt-fill user-icon"></i>
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    pattern: "[A-Za-zs]*", // Allows only letters and spaces
                                    title: "Please enter only text", // Message displayed when the pattern is not met
                                  },
                                }}
                                className="modal-input"
                                error={!!errors.city}
                                helperText={errors.city}
                              />
                            </div>

                            <div className="modal-input-container">
                              <TextField
                                label="Pincode"
                                name="pincode"
                                variant="outlined"
                                fullWidth
                                placeholder="Enter pincode"
                                value={formData.pincode}
                                onChange={handleChange}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <i className="bi bi-hash user-icon"></i>
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    maxLength: 6, // Restrict input length to 6 digits
                                    onKeyDown: (e) => {
                                      if (
                                        e.key !== "Backspace" &&
                                        e.key !== "Delete" &&
                                        isNaN(Number(e.key))
                                      ) {
                                        e.preventDefault();
                                      }
                                    },
                                  },
                                }}
                                className="modal-input"
                                error={!!errors.pincode}
                                helperText={errors.pincode}
                              />
                            </div>
                          </div>

                          {/* <div className="modal-input-container ">
                            <FormControl fullWidth variant="outlined">
                              <InputLabel id="state-label">State</InputLabel>
                              <Select
                                labelId="state-label"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                input={<OutlinedInput label="State" />}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <i className="bi bi-geo-alt-fill user-icon"></i>
                                  </InputAdornment>
                                }
                                label="State"
                                error={!!errors.state}
                              >
                                {states.map((state, index) => (
                                  <MenuItem key={index} value={state}>
                                    {state}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.state && (
                                <FormHelperText error>
                                  {errors.state}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </div> */}

                          {/* <Button
                variant="contained"
                color="primary"
                fullWidth
                className="confirm-button"
                onClick={handleSubmit}
              >
                Confirm order - Rs. 699.00{" "}
                <i className="bi bi-arrow-right button-icon"></i>
              </Button> */}

                          {/* <div className="add-btn-section">
                            <div
                              className="add-button-btn"
                              onClick={handleAddressChange}
                            >
                              <div className="add-btn-text">Save Address</div>
                            </div>
                          </div> */}
                        </div>

                        {loading && (
                          <>
                            <Dialog
                              open={loaderOpen}
                              onClose={handleClose}
                              maxWidth="md"
                              fullWidth
                            >
                              <div className="dialog-content">
                                <div className="loader-section">
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <CircularProgress />
                                    </div>
                                  </div>
                                  <div className="loader-content">
                                    Details are being saved. Please wait!
                                  </div>
                                </div>
                              </div>
                            </Dialog>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </>
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-6 col-sm-12 p-0">
              <div className="payment-details-card">
                <div className="card-title">
                  Price details ({finalCart?.items} Items)
                </div>
                <div className="price-spliter-section">
                  <div className="price-splitter">
                    <div className="spliter-title">
                      Total MRP (Inc. of Taxes)
                    </div>
                    <div className="spliter-value">₹{finalCart?.totalMRP}</div>
                  </div>
                  <div className="price-splitter">
                    <div className="spliter-title">Discount</div>
                    <div className="spliter-value">
                      - ₹{finalCart?.totalDiscount}
                    </div>
                  </div>
                  <div className="price-splitter">
                    <div className="spliter-title shipping-title">Shipping</div>
                    <div className="spliter-value ">
                      <span className="previous-price">
                        ₹{finalCart?.shipping}
                      </span>
                      <span className="shipping-value shipping-title">
                        Free
                      </span>{" "}
                    </div>
                  </div>
                </div>
                <div className=" total-splitter">
                  <div className="total-title">Total Amount</div>
                  <div className="total-title">₹{finalCart?.totalPrice}</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000} // Auto hide after 3 seconds
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          message="Ordered Successfully"
          action={<button onClick={() => setOpenSnackbar(false)}>Close</button>}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success">
            Ordered Successfully
          </Alert>
        </Snackbar>
        <LoginSignupDialog open={dialogOpen} onClose={handleCloseDialog} />
      </>
    );
  }
);

export default Address;
