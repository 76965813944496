import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/HeroSlider.css";

const HeroCarousel = ({ onBannerClick, from }) => {
  // State to store the screen width
  const [isMobile, setIsMobile] = useState(false);

  // Function to check screen width
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768); // Assuming 768px is the breakpoint for mobile
  };

  useEffect(() => {
    handleResize(); // Call on component mount
    window.addEventListener("resize", handleResize); // Add event listener on resize
    return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
  }, []);

  // const mens_watches = require("../assets/images/Banners/mens_watches.png");
  // const ladies_watches = require("../assets/images/Banners/ladies_watches.png");
  // const combo_watches = require("../assets/images/Banners/combo_watches.png");
  // const desktop_mens_watch = require("../assets/images/Banners/desktop_mens_watch.png");
  // const desktop_ladies_watch = require("../assets/images/Banners/desktop_ladies_watch.png");
  // const desktop_couples_watch = require("../assets/images/Banners/desktop_couples_watch.png");

  const [desktopBanner_1, setDesktopBanner_1] = useState();
  const [desktopBanner_2, setDesktopBanner_2] = useState();
  const [desktopBanner_3, setDesktopBanner_3] = useState();
  const [mobileBanner_1, setMobileBanner_1] = useState();
  const [mobileBanner_2, setMobileBanner_2] = useState();
  const [mobileBanner_3, setMobileBanner_3] = useState();

  useEffect(() => {
    if (from == "watches") {
      setDesktopBanner_1(
        require("../assets/images/Banners/desktop_mens_watch.png")
      );
      setDesktopBanner_2(
        require("../assets/images/Banners/desktop_ladies_watch.png")
      );
      setDesktopBanner_3(
        require("../assets/images/Banners/desktop_couples_watch.png")
      );
      setMobileBanner_1(require("../assets/images/Banners/mens_watches.png"));
      setMobileBanner_2(require("../assets/images/Banners/ladies_watches.png"));
      setMobileBanner_3(require("../assets/images/Banners/combo_watches.png"));
    } else if (from == "clothing") {
      setDesktopBanner_1(
        require("../assets/images/Banners/desktopBanner_1.png")
      );
      setDesktopBanner_2(
        require("../assets/images/Banners/desktopBanner_2.png")
      );
      setDesktopBanner_3(
        require("../assets/images/Banners/desktopBanner_3.png")
      );
      setMobileBanner_1(
        require("../assets/images/Banners/mobile_banner_2.png")
      );
      setMobileBanner_2(
        require("../assets/images/Banners/mobile_banner_3.png")
      );
      setMobileBanner_3(
        require("../assets/images/Banners/mobile_banner_1.png")
      );
    } else if (from == "kurtis") {
      setDesktopBanner_1(require("../assets/images/Banners/category_4.png"));
      setDesktopBanner_2(require("../assets/images/Banners/category_4.png"));
      setDesktopBanner_3(require("../assets/images/Banners/category_4.png"));
      setMobileBanner_1(require("../assets/images/Banners/category_4.png"));
      setMobileBanner_2(require("../assets/images/Banners/category_4.png"));
      setMobileBanner_3(require("../assets/images/Banners/category_4.png"));
    }
  }, [from]);

  const settings = {
    dots: true, // Shows dots at the bottom for navigation
    infinite: true, // Allows infinite loop sliding
    speed: 500, // Speed of transitions
    slidesToShow: 1, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: false, // Enables automatic sliding
    autoplaySpeed: 3000, // Delay between auto slide transitions
    responsive: [
      {
        breakpoint: 1024, // For large screens (desktop)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // For medium screens (tablets)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // For small screens (mobile devices)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const [swiped, setSwiped] = useState(false); // Track if swiped

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
    setSwiped(false); // Reset swiped state on touch start
  };

  const handleTouchEnd = (e) => {
    setTouchEndX(e.changedTouches[0].clientX);
    handleSwipe();
  };

  const handleSwipe = () => {
    const swipeDistance = touchEndX - touchStartX;

    // Define a threshold for swipe detection
    if (Math.abs(swipeDistance) > 30) {
      setSwiped(true); // Set swiped state to true
      return; // Detected a swipe, do nothing
    }

    // It was a click
    const activeIndex = getActiveSlideIndex(); // Get the currently active slide index
    const category = determineCategory(activeIndex);

    if (category) {
      onBannerClick(category);
    }
  };

  const getActiveSlideIndex = () => {
    // Logic to get the current active slide index
    // Replace this placeholder with the actual logic
    return 0; // Placeholder: return the current slide index (0, 1, or 2)
  };

  const determineCategory = (index) => {
    switch (index) {
      case 0:
        return "mens";
      case 1:
        return "womens";
      case 2:
        return "couples";
      default:
        return null;
    }
  };

  const handleClick = (index) => {
    // if (!swiped) {
    //   // Only trigger click if not swiped
    //   const category = determineCategory(index);
    //   if (category) {
    //     onBannerClick(category);
    //   }
    // }
  };

  return (
    <div
      style={{ width: "100%", margin: "0 auto" }}
      className="my-slider-wrapper"
    >
      <Slider
        {...settings}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div>
          <img
            onClick={() => handleClick(0)}
            src={isMobile ? mobileBanner_1 : desktopBanner_1}
            alt="Mens"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div>
          <img
            onClick={() => handleClick(1)}
            src={isMobile ? mobileBanner_2 : desktopBanner_2}
            alt="Ladies"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div>
          <img
            onClick={() => handleClick(2)}
            src={isMobile ? mobileBanner_3 : desktopBanner_3}
            alt="Couples"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </Slider>
    </div>
  );
};

export default HeroCarousel;
