import "../css/Loader.css"; // Import the CSS for styling

import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Loader = ({ loading }) => {
  return (
    <div className={`loader-backdrop ${loading ? "active" : ""}`}>
      <ClipLoader color="#36D7B7" loading={loading} size={50} />
    </div>
  );
};

export default Loader;
