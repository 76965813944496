// src/services/cartService.js
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/auth`; // Adjust this URL to match your backend

// Function for user registration (signup)

export const registerUser = (userData) => {
  return axios.post(`${BASE_URL}/register`, userData);
};

// Function for user login
export const loginUser = (loginData) => {
  return axios.post(`${BASE_URL}/login`, loginData);
};

// Function to fetch a user by ID
export const getUserById = (userId) => {
  return axios.get(`${BASE_URL}/user/${userId}`);
};

// Function to update a user
export const updateUser = (userId, userData) => {
  return axios.patch(`${BASE_URL}/user/${userId}`, userData, {
    headers: {
      "Content-Type": "application/json", // Ensure this is set correctly
    },
  });
};

// Function to delete a user's address
export const deleteUserAddress = (userId, addressId) => {
  return axios.delete(`${BASE_URL}/user/${userId}/address/${addressId}`, {
    headers: {
      "Content-Type": "application/json", // Ensure this is set correctly
    },
  });
};
