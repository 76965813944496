import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import ProductsRow from "./ProductsRow";
import PickAny from "./PickAny";
import { useParams } from "react-router-dom";

import { getProductsByCategory } from "../admin/Products/productService";
import { lab } from "color";
// TabPanel component to display content based on the selected tab
const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            paddingLeft: "0px", // Remove padding-left on mobile screens
            paddingRight: "0px", // Optional: Remove padding-right if needed
            paddingTop: 2, // Adjust the overall padding for mobile if necessary
            paddingBottom: 0, // Adjust the overall padding for mobile if necessary
            "@media (max-width: 600px)": {
              paddingLeft: "0px", // Remove padding-left on mobile screens
              paddingRight: "0px", // Optional: Remove padding-right if needed
              paddingTop: 2, // Adjust the overall padding for mobile if necessary
              paddingBottom: 0, // Adjust the overall padding for mobile if necessary
            },
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ResponsiveTabs = ({ tabLabels, from }) => {
  const { category } = useParams();
  const [value, setValue] = useState(0);
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");

  const handleChange = (event, newValue) => {
    console.log(newValue, "newValue");
    setValue(newValue);
    setSelectedLabel(tabLabels[newValue]);
  };

  const [products, setProducts] = useState([]);

  const fetchProducts = async (id) => {
    // alert("responsive-tabs-called");
    const response = await getProductsByCategory(id, "user");
    console.log(response, "response");
    setProducts(response.data);
  };

  useEffect(() => {
    // fetchProducts("671632c552d1ad2675d3f4a8");
    // alert("responsive-tabs-called");
    console.log(category, "category");
  }, []);

  useEffect(() => {
    setLabels(tabLabels);
  }, [tabLabels]);

  useEffect(() => {
    console.log(labels, "labels");
    // fetchProducts("671632c552d1ad2675d3f4a8");
    setSelectedLabel(labels[0]);
  }, [labels]);

  useEffect(() => {
    if (selectedLabel) {
      if (selectedLabel == "Couples Watches") {
        fetchProducts("67176acbbf89716ad3f3ed76");
      } else if (selectedLabel == "Mens") {
        fetchProducts("671632c552d1ad2675d3f4a8");
      } else if (selectedLabel == "Womens") {
        fetchProducts("67175299bf89716ad3f3eaf3");
      } else if (selectedLabel == "Printed Half Sleeve") {
        fetchProducts("6721d58c6f88e2740631ead0");
      } else if (selectedLabel == "Printed Full Sleeve") {
        fetchProducts("6721d5e06f88e2740631eae3");
      } else if (selectedLabel == "Mens Pants") {
        fetchProducts("6721da1b6f88e2740631eb69");
      } else if (selectedLabel == "Shorts") {
        fetchProducts("6721d92d6f88e2740631eb40");
      } else if (selectedLabel == "Regular Half Sleeve") {
        fetchProducts("67232a5c7fb2677318baba23");
      } else if (selectedLabel == "Regular Full Sleeve") {
        fetchProducts("67232f427fb2677318babae2");
      } else if (selectedLabel == "Elegant Popcorn Kurti") {
        fetchProducts("673f4da28f1b1d48c451637b");
      }
    }
    console.log(selectedLabel, "selectedLabel");
  }, [selectedLabel]);

  useEffect(() => {
    console.log(products, "products_tabs");
  }, [products]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": {
              minWidth: 100, // Adjust this value for mobile responsiveness
              textTransform: "capitalize", // Ensure label capitalization style
            },
            "& .Mui-selected": {
              // background: "linear-gradient(0deg, #f7eb9e 0%, #fffdee 98.13%)", // Linear gradient background
              // borderTop: "2px solid #d2a600", // A suitable border color for contrast
              // borderTopLeftRadius: "8px",
              // borderTopRightRadius: "8px",
              // borderBottom: "2px solid #d2a600 !important",
            },

            "@media (max-width:600px)": {
              "& .MuiTab-root": {
                minWidth: 80, // Adjust this value for smaller screens
              },
              "& .Mui-selected": {
                // background: "linear-gradient(0deg, #f7eb9e 0%, #fffdee 98.13%)", // Linear gradient background
                // borderTop: "2px solid #d2a600", // A suitable border color for contrast
                // borderTopLeftRadius: "8px",
                // borderTopRightRadius: "8px",
                // borderBottom: "2px solid #d2a600 !important",
              },
            },
          }}
        >
          {tabLabels.map((label, index) => (
            <Tab
              key={index} // Use a unique key for each tab
              label={label.charAt(0).toUpperCase() + label.slice(1)} // Capitalize the first letter
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
              style={{ textTransform: "capitalize" }}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ProductsRow products={products} from={from} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProductsRow products={products} from={from} />
        {/* <PickAny products={products} from="normal" /> */}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ProductsRow products={products} from={from} />
        {/* <PickAny products={products} from="normal" /> */}
      </TabPanel>
    </Box>
  );
};

export default ResponsiveTabs;
