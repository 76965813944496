// src/ContactUsPage.js
import React, { useEffect } from "react";
import { Container, Typography, Grid, Paper, Link } from "@mui/material";
import "../css/ContactUsPage.css"; // Import custom CSS
import MainFooter from "../Components/MainFooter";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container maxWidth="md" className="contact-container">
        <Paper elevation={3} className="contact-paper">
          <Typography variant="h5" gutterBottom className="contact-header">
            Contact Us
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            className="contact-section-title"
          >
            Our Address
          </Typography>
          <Typography variant="body1" paragraph className="contact-info">
            Global India Shop
            <br />
            120D st.marys street
            <br />
            Maravan Kudirupu, Nagercoil, 629002
            <br />
            India
          </Typography>

          <Typography
            variant="h6"
            gutterBottom
            className="contact-section-title"
          >
            Phone
          </Typography>
          <Typography variant="body1" paragraph className="contact-info">
            +91 79041 39207
          </Typography>

          <Typography
            variant="h6"
            gutterBottom
            className="contact-section-title"
          >
            Email
          </Typography>
          <Typography variant="body1" paragraph className="contact-info">
            <Link
              href="mailto:support@globalindiashop.com"
              className="contact-link"
            >
              contact.globalindiashop@gmail.com
            </Link>
          </Typography>

          <Typography
            variant="h6"
            gutterBottom
            className="contact-section-title"
          >
            Business Hours
          </Typography>
          <Typography variant="body1" paragraph className="contact-info">
            Monday - Friday: 9:00 AM - 6:00 PM
            <br />
            Saturday: 10:00 AM - 4:00 PM
            <br />
            Sunday: Closed
          </Typography>

          {/* Optional: Embed a Google Map */}
          {/* <Typography
            variant="h6"
            gutterBottom
            className="contact-section-title"
          >
            Find Us
          </Typography>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15797.845719971318!2d77.42304909999999!3d8.15617755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f0555be0113f%3A0x9473709e47ebeee8!2sMaravankudieruppu%2C%20Tamil%20Nadu%20629002!5e0!3m2!1sen!2sin!4v1723814554499!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0, width: "350px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div> */}
        </Paper>
      </Container>
      <MainFooter />
    </>
  );
};

export default ContactUs;
