import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../css/Successfull.css"; // Import custom CSS
import MainFooter from "../Components/MainFooter";

const Successfull = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  // Calculate the delivery date
  const today = new Date();
  today.setDate(today.getDate() + 5);
  const deliveryDate = today.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return (
    <>
      <div className="success-container">
        <div className="success-content">
          <div className="icon-container">
            <i className="bi bi-bag-check-fill"></i>
          </div>
          <h1>Order Successfully Placed</h1>
          {/* <p>
            Your order for the product{" "}
            <span className="badge bg-primary">
              Smart Learning Pillow for Kids: Engage & Educate
            </span>
            has been successfully placed.
          </p> */}
          <p className="pt-2">
            Your order will be delivered by our delivery partner on{" "}
            <strong className="delivery">{deliveryDate}</strong>.
          </p>
          <div className="social-section">
            {" "}
            <p className="follow-us">
              Follow us on social medias for updates on our upcoming products
              with <span className="offer">60%</span> offer
            </p>
            <div className="social-icons">
              <a
                href="https://youtube.com/@globalindiashop?si=C_XDNLErd_1dksuu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-youtube"></i>
              </a>
              <a
                href="https://www.instagram.com/global_india_shop?igsh=MTE0N3ExYjJ5NXAzMA=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-instagram"></i>
              </a>
              <a
                href="https://x.com/GlobalIndiaShop"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-twitter"></i>
              </a>
            </div>
          </div>
          <p className="mb-1 mt-3">
            Check our website in the future for awesome products!
          </p>
          {/* <p className="mb-5">Thank you for choosing Global India Shop!</p> */}
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default Successfull;
