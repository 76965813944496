// src/components/Visitors.js
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import {
  recordVisit,
  getVisitorData,
} from "../Categories/services/visitorServices";
import "../../css/admin-css/Visitors.css";

const Visitors = () => {
  const [visitorData, setVisitorData] = useState([]);
  const [todayCount, setTodayCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleVisitorData = async () => {
      try {
        // Record the visit first
        await recordVisit();

        // Fetch visitor data for the last 7 days
        const endDate = new Date().toISOString().split("T")[0];
        const startDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0];

        const response = await getVisitorData(startDate, endDate);
        const data = response.data;
        setVisitorData(data);

        // Get today's visitor count
        const today = data.find((item) => item.date === endDate);
        setTodayCount(today ? today.visitorCount : 0);
      } catch (error) {
        console.error("Error handling visitor data:", error);
      } finally {
        setLoading(false);
      }
    };

    handleVisitorData();
  }, []); // Empty dependency array ensures this only runs once on mount

  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-lg-2">
          <div className="visitor-card">
            <h3>Today's Visitors</h3>
            <p className="visitor-count">{todayCount}</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="chart-container">
            {loading ? (
              <p>Loading data...</p>
            ) : (
              <LineChart
                width={600}
                height={300}
                data={visitorData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="visitorCount"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            )}
          </div>
        </div>

        <div className="col-lg-4">
          <h6>Daily Visitor Count</h6>
          <table className="visitor-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Visitor Count</th>
              </tr>
            </thead>
            <tbody>
              {visitorData.map((item) => (
                <tr key={item.date}>
                  <td>{item.date}</td>
                  <td>{item.visitorCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Visitors;
