import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const DescriptionModal = ({ open, description, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Product Descriptions</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">Specifications</Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: description.specifications }}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Features
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: description.features }}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          More Info
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: description.moreInfo }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionModal;
