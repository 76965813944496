import React, { useState } from "react";
import ProductItem from "./ProductItem"; // Import the ProductItem component

const ProductList = () => {
  const [products, setProducts] = useState([
    { id: 1, name: "Product 1", price: "$10" },
    { id: 2, name: "Product 2", price: "$20" },
    { id: 3, name: "Product 3", price: "$30" },
    // Add more products as needed
  ]);

  const moveProduct = (fromIndex, toIndex) => {
    const updatedProducts = [...products];
    const [movedProduct] = updatedProducts.splice(fromIndex, 1); // Remove product from the old index
    updatedProducts.splice(toIndex, 0, movedProduct); // Insert it at the new index
    setProducts(updatedProducts);

    // Update the product order in the database
    updateProductOrderInDatabase(updatedProducts);
  };

  const updateProductOrderInDatabase = (updatedProducts) => {
    // Implement your API call to update the product order in the database here
    console.log("Updating product order in database", updatedProducts);
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Product Name</th>
          <th>Price</th>
          {/* Other headers */}
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => (
          <ProductItem
            key={product.id}
            product={product}
            index={index}
            moveProduct={moveProduct}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ProductList;
