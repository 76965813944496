// customerRatings.js
const customer_image1 = require("../assets/babypillow/customer_image1.png");
const customer_image2 = require("../assets/babypillow/customer_image2.png");
const customer_image3 = require("../assets/babypillow/customer_image3.png");
const customer_image4 = require("../assets/babypillow/customer_image4.png");
const customer_image5 = require("../assets/babypillow/customer_image5.png");
const customer_image6 = require("../assets/babypillow/customer_image6.png");
const customer_image7 = require("../assets/babypillow/customer_image7.png");
const customer_image8 = require("../assets/babypillow/customer_image8.png");
const customer_image9 = require("../assets/babypillow/customer_image9.png");
const customer_image10 = require("../assets/babypillow/customer_image10.png");
const customer_image11 = require("../assets/babypillow/customer_image11.png");
const customer_image12 = require("../assets/babypillow/customer_image12.png");
const customer_image13 = require("../assets/babypillow/customer_image13.png");
const customer_image14 = require("../assets/babypillow/customer_image14.png");
const customer_image15 = require("../assets/babypillow/customer_image15.png");

export const customerRatings = [
  {
    images: [customer_image14, customer_image6, customer_image15],
    customerName: "Aarav",
    customerPlace: "Maharashtra",
    purchasedDate: "3 hrs ago",
    starCount: "5",
    comment: "अच्छी गुणवत्ता",
  },
  {
    images: [customer_image3, customer_image4],
    customerName: "Vivaan Patel",
    customerPlace: "Gujarat",
    purchasedDate: "4 hrs ago",
    starCount: "4",
    comment: "Best quality, worth every rupee!",
  },
  {
    images: [customer_image5],
    customerName: "Aditya",
    customerPlace: "Telungana",
    purchasedDate: "8 hrs ago",
    starCount: "5",
    comment: "మంచి ఉత్పత్తి నా పిల్లలు ఇష్టపడతారు",
  },
  {
    images: [customer_image7, customer_image8],
    customerName: "Vihaan",
    customerPlace: "Karnataka",
    purchasedDate: "1 Day ago",
    starCount: "4",
    comment: "The product exceeded my expectations!",
  },
  {
    images: [customer_image8, customer_image9],
    customerName: "Raveena nair",
    customerPlace: "Kerala",
    purchasedDate: "1 Day ago",
    starCount: "5",
    comment: "ഇത് സമാനമാണ്",
  },
  {
    images: [customer_image10, customer_image9],
    customerName: "Arjun",
    customerPlace: "Telangana",
    purchasedDate: "1 Day ago",
    starCount: "5",
    comment: "Highly durable and excellent service.",
  },
  {
    images: [customer_image11, customer_image12],
    customerName: "Sai Kumar",
    customerPlace: "Andhra Pradesh",
    purchasedDate: "2 Day ago",
    starCount: "4",
    comment: "very fast delivery thankss",
  },
  {
    images: [customer_image10, customer_image13],
    customerName: "Ishaan",
    customerPlace: "Rajasthan",
    purchasedDate: "2 Day ago",
    starCount: "4",
    comment: "I am very happy with my purchase",
  },
  {
    images: [customer_image2, customer_image1],
    customerName: "Arnav J",
    customerPlace: "Uttar Pradesh",
    purchasedDate: "3 Day ago",
    starCount: "5",
    comment: "Great value for money, highly recommended.",
  },
  {
    images: [],
    customerName: "Rocky Ragu",
    customerPlace: "West Bengal",
    purchasedDate: "4 Day ago",
    starCount: "5",
    comment: "Fantastic quality and timely delivery",
  },
  {
    images: [customer_image4, customer_image5],
    customerName: "Kabir",
    customerPlace: "Bihar",
    purchasedDate: "1 week ago",
    starCount: "5",
    comment: "Amazing quality, would buy again",
  },
  {
    images: [customer_image6],
    customerName: "Rohan Evans",
    customerPlace: "Haryana",
    purchasedDate: "1 week ago",
    starCount: "4",
    comment: "taop notch quality",
  },
  {
    images: [],
    customerName: "Kartik",
    customerPlace: "Punjab",
    purchasedDate: "1 week ago",
    starCount: "5",
    comment: "ordered 2 pillows both are awesome quality",
  },
  {
    images: [],
    customerName: "A.Reyansh",
    customerPlace: "Odisha",
    purchasedDate: "1 week ago",
    starCount: "4",
    comment: "nice",
  },
  {
    images: [],
    customerName: "Saanvi S",
    customerPlace: "Assam",
    purchasedDate: "1 week ago",
    starCount: "5",
    comment: "Great product",
  },
];

// {
//     images: [customer_image14, customer_image15],
//     customerName: "Mira Shah",
//     customerPlace: "Jammu and Kashmir",
//     purchasedDate: "6 weeks ago",
//     starCount: "5",
//     comment: "Wonderful experience, product quality is superb!",
//   },
