import React, { useState } from "react";
import "../css/reviews.css";
import ReviewImageGallery from "./ReviewImageGallery";
import { customerRatings } from "./customerRatings"; // Adjust the path as necessary
import { Dialog, IconButton, Button } from "@mui/material";
import { Close } from "@mui/icons-material";

function Reviews() {
  const [visibleCount, setVisibleCount] = useState(3); // Initially show 3 reviews

  const loadMoreReviews = () => {
    setVisibleCount((prevCount) =>
      Math.min(prevCount + 3, customerRatings.length)
    );
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <ReviewImageGallery />
      <div className="ratings-main">
        <div className="ratings-card">
          {customerRatings.slice(0, visibleCount).map((rating, index) => (
            <div key={index} className="review-card">
              <div className="review-star-count">
                {/* {Array(Number(rating.starCount))
                  .fill()
                  .map((_, idx) => (
                    <>
                      <span className="star filled"></span>
                      <span key={idx} className="ratingpage-star"></span>
                    </>
                  ))} */}

                {Array.from({ length: 5 }).map((_, idx) => {
                  let className = "star";
                  if (idx < Number(rating.starCount)) {
                    className += " filled";
                  }
                  // Optionally handle half stars if needed
                  return <span key={idx} className={className}></span>;
                })}
              </div>
              <p className="review-comment">{rating.comment}</p>
              <div className="review-images">
                {rating.images.map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={image}
                    alt={`Review ${index + 1}`}
                    className="review-image"
                    onClick={() => handleImageClick(image)}
                    title={`Click to view`}
                  />
                ))}
              </div>
              <div className="review-details">
                <span className="review-customer-name">
                  <i className="bi bi-person-circle"></i>
                  {rating.customerName},
                </span>
                <span className="review-customer-place">
                  {rating.customerPlace}
                </span>
              </div>
              <div className="verified-section">
                <div className="review-purchased-date">
                  <i className="bi bi-patch-check-fill"></i>
                </div>
                <div className="review-purchased-date">Verified Purchase .</div>
                <div className="review-purchased-date">
                  {rating.purchasedDate}
                </div>
              </div>
            </div>
          ))}
          <div className="scroll-top">
            <div className="show-more">
              {visibleCount < customerRatings.length && (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    loadMoreReviews();
                  }}
                >
                  See more reviews
                </a>
              )}
            </div>
            <div
              className="go-up"
              onClick={scrollToTop}
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-arrow-up-circle-fill go-up-icon"></i>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={!!selectedImage}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "100vw", // Adjust width to fit within viewport
            maxHeight: "100vh", // Adjust height to fit within viewport
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <div className="dialog-content">
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              className="preview-image"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          )}
          <div className="review-image-close-wrapper">
            <Button
              className="close-button"
              onClick={handleClose}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default Reviews;
