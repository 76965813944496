// src/services/cartService.js
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/favorite`; // Adjust this URL to match your backend
export const addToFavorite = (userId, cartItem) => {
  return axios.post(`${BASE_URL}/addFavorite`, {
    userId, // Send the userId to the backend
    item: cartItem, // Send the cartItem as 'item'
  });
};

// New function to get favorites by user ID
export const getFavoritesByUserId = (userId) => {
  return axios.get(`${BASE_URL}/getFavorites/${userId}`);
};
