// src/components/ProductView.js
import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../css/checkout.css";
import Snackbar from "@mui/material/Snackbar";
import MainFooter from "../Components/MainFooter";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  OutlinedInput,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { Add, Remove } from "@mui/icons-material";
const states = [
  "Select State",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedProduct = location.state && location.state.chosedProduct;
  console.log(location, "selectedProduct");
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(null);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  // Update totalPrice whenever quantity changes
  useEffect(() => {
    validateFields();
  }, []);
  useEffect(() => {
    setTotalPrice(quantity * selectedProduct?.price);
  }, [quantity, selectedProduct?.price]);

  const handleClose = () => {
    setLoaderOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const [formData, setFormData] = useState({
    fullName: "",
    primaryPhone: "",
    address: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
    state: states[0],
    quantity: quantity,
    color: selectedProduct?.selectedColor,
  });

  const [errors, setErrors] = useState({});

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    // Restrict input to digits only for phone and pincode fields
    if (name === "primaryPhone") {
      updatedValue = value.replace(/\D/g, "").slice(0, 10); // Allow only 10 digits
    } else if (name === "pincode") {
      updatedValue = value.replace(/\D/g, "").slice(0, 6); // Allow only 6 digits
    }

    // Update form data
    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));

    // Clear errors as soon as user starts typing
    if (errors[name] && updatedValue) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    console.log(formData, "formData");
  };

  // Validate fields
  const validateFields = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = "Full Name is required";
    if (!formData.primaryPhone)
      newErrors.primaryPhone = "Primary Phone Number is required";
    if (!formData.address) newErrors.address = "Complete Address is required";
    if (!formData.landmark) newErrors.landmark = "Landmark is required";
    if (!formData.pincode) newErrors.pincode = "Pincode is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    // Validate numbers
    if (formData.primaryPhone && !/^\d{10}$/.test(formData.primaryPhone))
      newErrors.primaryPhone = "Primary Phone Number must be exactly 10 digits";
    if (formData.pincode && !/^\d{6}$/.test(formData.pincode))
      newErrors.pincode = "Pincode must be exactly 6 digits";
    if (formData.state == "Select State") {
      newErrors.state = "Please Select State";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submit
  const handleSubmit = async () => {
    if (validateFields()) {
      try {
        setLoading(true);
        setLoaderOpen(true);
        // Console the form data
        console.log(formData, "formData");
        // Example API endpoint
        const response = await fetch("https://api.globalindiashop.com/users", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (result?.status === true) {
          setLoading(false);
          setLoaderOpen(false);
          setOpenSnackbar(true); // Show Snackbar
          setTimeout(() => {
            setOpenSnackbar(false); // Hide Snackbar
            navigate("/ordered-successfully");
          }, 2000);
        }
        console.log("API Response:", result);
        // Handle success (e.g., show a success message or close the modal)
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
        setLoaderOpen(false);
        // Handle error (e.g., show an error message)
      }
    }
  };

  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleQuanitiyChange = (event) => {
    const value = event.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setQuantity(Number(value));
    }
  };
  const arrivingDate = new Date();
  arrivingDate.setDate(arrivingDate.getDate() + 5);
  const formattedDate = arrivingDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return (
    <>
      <div className="container ">
        <div className="row checkout-form">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 only-desktop">
            <div className="summary-section">
              <div className="dialog-title text-start py-2">
                {selectedProduct?.name}
              </div>
              <img
                className="checkout-image"
                src={selectedProduct?.image}
                alt=""
              />
              <div className="product-details">
                <div className="dialog-title py-2">Order-Summary</div>
                <div className="summary-items">
                  <div className="summary-subheads">Quantity :</div>
                  <div className="summary-values">{quantity}</div>
                </div>
                <div className="summary-items">
                  <div className="summary-subheads">Color :</div>
                  <div className="summary-values">
                    {selectedProduct?.selectedColor}
                  </div>
                </div>
                <div className="summary-items">
                  <div className="summary-subheads">Price :</div>
                  <div className="summary-values">
                    ₹ {selectedProduct?.price}
                  </div>
                </div>
                <div className="summary-items">
                  <div className="summary-subheads">Shipping :</div>
                  <div className="summary-values">Free</div>
                </div>
                <hr />
                <div className="summary-items">
                  <div className="summary-subheads total">Total :</div>
                  <div className="summary-values">₹ {totalPrice}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
            <div className="dialog-title text-start pb-0 pt-2">
              Place your order :
            </div>
            <div>
              <div className="modal-input-container ">
                {/* <div className="qty-container">
                  <div className="choose-qty">Select Quantity</div>
                  <div className="plus-minus-div">
                    <IconButton
                      className="quantity-button decrease-btn"
                      onClick={handleDecrease}
                      disabled={quantity <= 1}
                    >
                      <Remove />
                    </IconButton>
                    <TextField
                      className="quantity-input"
                      value={quantity}
                      onChange={handleQuanitiyChange}
                      inputProps={{
                        style: { textAlign: "center" },
                      }}
                    />
                    <IconButton
                      className="quantity-button increase-btn"
                      onClick={handleIncrease}
                    >
                      <Add />
                    </IconButton>
                  </div>
                </div> */}
              </div>
              <div className="d-lg-flex d-xl-flex d-md-flex  justify-content-between">
                <div className="modal-input-container  mr-2">
                  <TextField
                    label="Full Name"
                    name="fullName"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter your full name"
                    value={formData.fullName}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="bi bi-person-fill user-icon"></i>
                        </InputAdornment>
                      ),
                    }}
                    className="modal-input"
                    error={!!errors.fullName}
                    helperText={errors.fullName}
                  />
                </div>
                <div className="modal-input-container  ">
                  <TextField
                    label="Primary Phone Number"
                    name="primaryPhone"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter your phone number"
                    value={formData.primaryPhone}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="bi bi-telephone-fill user-icon"></i>
                        </InputAdornment>
                      ),
                      inputProps: {
                        maxLength: 10, // Restrict input length to 10 digits
                        onKeyDown: (e) => {
                          if (
                            e.key !== "Backspace" &&
                            e.key !== "Delete" &&
                            isNaN(Number(e.key))
                          ) {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                    className="modal-input"
                    error={!!errors.primaryPhone}
                    helperText={errors.primaryPhone}
                  />
                </div>
              </div>

              <div className="d-lg-flex d-xl-flex d-md-flex  justify-content-between">
                <div className="modal-input-container">
                  <TextField
                    label="Complete Address"
                    name="address"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter your complete address"
                    value={formData.address}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="bi bi-geo-alt-fill user-icon"></i>
                        </InputAdornment>
                      ),
                    }}
                    className="modal-input"
                    error={!!errors.address}
                    helperText={errors.address}
                  />
                </div>
                <div className="modal-input-container">
                  <TextField
                    label="House, Apartment, Landmark, etc"
                    name="landmark"
                    placeholder="Nearby school, hospital, shop"
                    variant="outlined"
                    fullWidth
                    value={formData.landmark}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="bi bi-crosshair2 user-icon"></i>
                        </InputAdornment>
                      ),
                    }}
                    className="modal-input"
                    error={!!errors.landmark}
                    helperText={errors.landmark}
                  />
                </div>
              </div>

              <div className="d-lg-flex d-xl-flex d-md-flex  justify-content-between">
                <div className="modal-input-container">
                  <TextField
                    label="Pincode"
                    name="pincode"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter your pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="bi bi-hash user-icon"></i>
                        </InputAdornment>
                      ),
                      inputProps: {
                        maxLength: 6, // Restrict input length to 6 digits
                        onKeyDown: (e) => {
                          if (
                            e.key !== "Backspace" &&
                            e.key !== "Delete" &&
                            isNaN(Number(e.key))
                          ) {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                    className="modal-input"
                    error={!!errors.pincode}
                    helperText={errors.pincode}
                  />
                </div>
                <div className="modal-input-container">
                  <TextField
                    label="City"
                    name="city"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter your city"
                    value={formData.city}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="bi bi-geo-alt-fill user-icon"></i>
                        </InputAdornment>
                      ),
                    }}
                    className="modal-input"
                    error={!!errors.city}
                    helperText={errors.city}
                  />
                </div>
              </div>

              <div className="modal-input-container ">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    labelId="state-label"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    input={<OutlinedInput label="State" />}
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="bi bi-geo-alt-fill user-icon"></i>
                      </InputAdornment>
                    }
                    label="State"
                    error={!!errors.state}
                  >
                    {states.map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.state && (
                    <FormHelperText error>{errors.state}</FormHelperText>
                  )}
                </FormControl>
              </div>

              {/* <Button
                variant="contained"
                color="primary"
                fullWidth
                className="confirm-button"
                onClick={handleSubmit}
              >
                Confirm order - Rs. 699.00{" "}
                <i className="bi bi-arrow-right button-icon"></i>
              </Button> */}
            </div>
            <hr />

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="summary-section">
                  <h6>Review items and Place Order</h6>
                  <div className="arriving">Arriving {formattedDate}</div>
                  <div className="shipped">
                    Securely Processed Shipments by Global India Shop
                  </div>
                  <div className="review-product">
                    <div>
                      <img
                        className="checkout-review-img"
                        src={selectedProduct?.image}
                        alt=""
                      />
                    </div>
                    <div className="review-content ">
                      <div className="summary-subheads pb-2">
                        {selectedProduct?.name}
                      </div>
                      <div className="pricing-section pb-2">
                        <span className="mrp">M.R.P:</span>
                        <span className="previous-price">
                          ₹ {selectedProduct?.previous_price}
                        </span>
                        <span className="checkout-price-style">
                          ₹ {selectedProduct?.price}
                        </span>
                      </div>
                      <div className="pricing-section pb-2">
                        <span className="mrp">Payment Method:</span>
                        <span className="summary-subheads text-success font-weight-bold">
                          Cash On Delivery
                        </span>
                      </div>

                      <div className="product-details only-mobile">
                        <div className="summary-items">
                          <div className="summary-subheads">Quantity :</div>
                          <div className="summary-values">{quantity}</div>
                        </div>
                        <div className="summary-items">
                          <div className="summary-subheads">Color :</div>
                          <div className="summary-values">
                            {selectedProduct?.selectedColor}
                          </div>
                        </div>
                        <div className="summary-items">
                          <div className="summary-subheads">
                            Price (1 Item) :
                          </div>
                          <div className="summary-values">
                            ₹ {selectedProduct?.price}
                          </div>
                        </div>
                        <div className="summary-items">
                          <div className="summary-subheads">Shipping :</div>
                          <div className="summary-values">Free</div>
                        </div>
                        <hr />
                        <div className="summary-items">
                          <div className="summary-subheads total">Total :</div>
                          <div className="summary-values">₹ {totalPrice}</div>
                        </div>
                      </div>

                      <div className="place-order-wrapper">
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          className="confirm-button"
                          onClick={handleSubmit}
                        >
                          Confirm order - Rs {totalPrice}
                          <i className="bi bi-arrow-right button-icon"></i>
                        </Button>
                      </div>

                      {loading && (
                        <>
                          <Dialog
                            open={loaderOpen}
                            onClose={handleClose}
                            maxWidth="md"
                            fullWidth
                          >
                            <div className="dialog-content">
                              <div className="loader-section">
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <CircularProgress />
                                  </div>
                                </div>
                                <div className="loader-content">
                                  Details are being saved. Please wait!
                                </div>
                              </div>
                            </div>
                          </Dialog>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // Auto hide after 3 seconds
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        message="Ordered Successfully"
        action={<button onClick={() => setOpenSnackbar(false)}>Close</button>}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          Ordered Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default Checkout;
