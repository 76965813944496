// src/services/productService.js
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/product`;
export const createProduct = (categoryId, formData) => {
  return axios.post(`${BASE_URL}/create/${categoryId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// Service function to get products by category
export const getProductsByCategory = (categoryId, caller) => {
  return axios.get(`${BASE_URL}/category/${categoryId}`, {
    params: { caller },
  });
};

export const getProductById = (productId) => {
  return axios.get(`${BASE_URL}/product/${productId}`);
};

export const updateProduct = (id, formData) => {
  return axios.put(`${BASE_URL}/update/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteProduct = (id) => {
  return axios.delete(`${BASE_URL}/delete/${id}`);
};

// Function to update product order
export const updateProductOrder = (categoryId, productOrder) => {
  return axios.put(`${BASE_URL}/category/${categoryId}/reorder`, {
    productOrder,
  });
};
