import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import "../css/admin-css/AdminDashboard.css"; // Import the CSS file for styling

const AdminDashboard = () => {
  return (
    <div className="dashboard-container">
      <aside className="side-menu">
        <ul>
          <li>
            <NavLink to="/admin-dashboard/visitors" activeClassName="active">
              Visitors
            </NavLink>
          </li>
          <li>
            <NavLink to="add-category" activeClassName="active">
              Categories
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin-dashboard/users" activeClassName="active">
              Users
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin-dashboard/orders" activeClassName="active">
              Orders
            </NavLink>
          </li>
        </ul>
      </aside>
      <section className="dashboard-content">
        {/* The Outlet will render the selected route */}
        <Outlet />
      </section>
    </div>
  );
};

export default AdminDashboard;
