import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import axios from "axios";
import "../../css/loginSignup.css";
import CloseIcon from "@mui/icons-material/Close"; // Importing Close icon
import PersonIcon from "@mui/icons-material/Person"; // Importing User Icon
import EmailIcon from "@mui/icons-material/Email"; // Importing Email Icon
import PhoneIcon from "@mui/icons-material/Phone"; // Importing Phone Icon
import LockIcon from "@mui/icons-material/Lock"; // Importing Lock Icon for Password
import { Email, Password } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility"; // Importing Visibility icon
import VisibilityOff from "@mui/icons-material/VisibilityOff"; // Importing VisibilityOff icon
// import {
//   registerUser,
//   loginUser,
// } from "../admin/Categories/services/authService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registerAdmin, loginAdmin } from "../adminServices/adminServices";
import { useNavigate } from "react-router-dom";
const AdminLogin = ({ open, onClose }) => {
  const navigate = useNavigate();

  const [isSignup, setIsSignup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({}); // State to hold error messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear errors on input change
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    // Check if all fields are filled based on the current form
    if (isSignup) {
      // For signup, check all fields
      for (const [key, value] of Object.entries(formData)) {
        if (!value) {
          newErrors[key] = `${key.replace(/([A-Z])/g, " $1")} is required`; // Create dynamic error messages
          isValid = false;
        }
      }

      // Validate email format
      if (formData.email && !validateEmail(formData.email)) {
        newErrors.email = "Invalid email address";
        isValid = false;
      }

      // Check if password and confirm password match
      if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = "Passwords do not match";
        isValid = false;
      }
    } else {
      // For login, check only email and password
      if (!formData.email) {
        newErrors.email = "Email is required";
        isValid = false;
      } else if (!validateEmail(formData.email)) {
        newErrors.email = "Invalid email address";
        isValid = false;
      }

      if (!formData.password) {
        newErrors.password = "Password is required";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    try {
      // Call the appropriate API based on signup or login
      // Prepare data for submission
      const dataToSubmit = isSignup
        ? {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            password: formData.password,
          }
        : {
            email: formData.email,
            password: formData.password,
          };
      console.log("register called");

      const response = isSignup
        ? await registerAdmin(dataToSubmit)
        : await loginAdmin(dataToSubmit);
      console.log(response, "response");
      if (response) {
        toast.success(`Logged in as ADMIN`, {
          position: "top-center",
          autoClose: 3000,
        });
        localStorage.setItem("adminToken", response.token); // Store the token
        navigate("/admin-dashboard");
      }
      console.log(response, "registerResponse");
      onClose();
    } catch (error) {}
  };

  return (
    <>
      <div className="admin-signup">
        {isSignup ? "Sign Up" : "Log In"}
        {showPopup && (
          <div className="info-popup active">
            <p className="popup-content">Benefits of registering:</p>
            <ul className="popup-content">
              <li>Your address will be saved for next time.</li>
              <li>You can track your orders.</li>
              <li>You'll be informed about future offers.</li>
            </ul>
          </div>
        )}
        {isSignup && (
          <>
            <div className="field-wrapper mb-2">
              <div className="field-spliter">
                <TextField
                  autoFocus
                  margin="dense"
                  name="firstName"
                  label="First Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="firstname"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
              </div>
              <div className="field-spliter">
                <TextField
                  margin="dense"
                  name="lastName"
                  label="Last Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </div>
            </div>
          </>
        )}

        <div className="mb-2">
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!!errors.email}
            helperText={errors.email}
          />
        </div>

        <div className="mb-2">
          <TextField
            margin="dense"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.password}
            helperText={errors.password}
          />
        </div>

        {isSignup && (
          <>
            <div className="mb-2">
              <TextField
                margin="dense"
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                variant="outlined"
                value={formData.confirmPassword}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
            </div>
          </>
        )}
        <div className="ishas-section">
          <div className="ishas-question">
            {isSignup ? "Already have an account?" : "Don't have an account?"}{" "}
          </div>
          <div className="ishas-text" onClick={() => setIsSignup(!isSignup)}>
            {isSignup ? "Log In" : "Sign Up"}
          </div>
        </div>

        <button onClick={handleSubmit} className="login-btn">
          {isSignup ? "Sign Up" : "Log In"}
        </button>
      </div>
    </>
  );
};

export default AdminLogin;
