import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { services } from "../../services/services";

const ImageListModal = ({ open, images, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Product Images</DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={`${image.replace(/\\/g, "/")}`} // Correct the URL
              alt={`Product Image ${index + 1}`}
              style={{ width: "20%", margin: "10px" }} // Adjust image width and margin
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageListModal;
