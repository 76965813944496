// src/services/categoryService.js
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/category`;
export const createCategory = (formData) => {
  return axios.post(`${BASE_URL}/create`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getAllCategories = () => {
  return axios.get(`${BASE_URL}/all`);
};

export const updateCategory = (id, formData) => {
  return axios.put(`${BASE_URL}/update/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteCategory = (id) => {
  return axios.delete(`${BASE_URL}/delete/${id}`);
};
