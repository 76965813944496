import axios from "axios";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify"; // Make sure you import ToastContainer too
import "react-toastify/dist/ReactToastify.css"; // Import the CSS here
const RazorPayTest = () => {
  const [product] = useState({
    name: "Sample Product",
    price: 1, // Price in INR
  });

  const [orderId, setOrderId] = useState(null);

  const createOrder = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/payments/initiate`,

        {
          amount: 1, // Amount in INR (500 INR)
          currency: "INR", // Currency for the transaction
          receipt: "receipt#1", // Unique identifier for this payment
        }
      );

      if (response.data.orderId) {
        setOrderId(response.data.orderId); // Save the order ID to state
        console.log("Order created:", response.data.orderId);
      } else {
        console.error("Error creating order:", response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const openRazorpay = async () => {
    const isScriptLoaded = await loadRazorpayScript();
    if (!isScriptLoaded) {
      toast.error("Razorpay SDK failed to load. Are you online?.", {
        position: toast?.POSITION?.TOP_CENTER,
        autoClose: 3000,
      });
      return;
    }
    // Create order by calling the backend
    const orderData = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/payments/create-order`,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: product.price * 100,
          currency: "INR",
          receipt: "receipt#1",
        }), // Amount in paise
      }
    ).then((response) => response.json());

    console.log(orderData, "orderData");

    if (!orderData.orderId) {
      toast.error("Error creating order. Please try again.", {
        position: toast?.POSITION?.TOP_CENTER,
        autoClose: 3000,
      });
      return;
    }

    const options = {
      key: "rzp_live_Rg5roT6QcJHuL4", // Replace with your Razorpay Key ID
      amount: orderData.amount, // Amount in currency subunits (paise)
      currency: orderData.currency,
      name: product.name,
      description: "Purchase Product",
      order_id: orderData.orderId, // Order ID generated by backend
      handler: async (response) => {
        // Send payment details to backend for verification
        const verificationResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/payments/verify`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            }),
          }
        ).then((res) => res.json());

        if (verificationResponse.message === "Payment verified successfully") {
          toast.success("Payment Successful!", {
            position: toast?.POSITION?.TOP_CENTER,
            autoClose: 3000, // Auto close after 3 seconds
          });
        } else {
          toast.error("Payment Verification Failed", {
            position: toast?.POSITION?.TOP_CENTER,
            autoClose: 3000,
          });
        }
      },
      prefill: {
        name: "Customer Name",
        email: "customer@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#F37254",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  return (
    <>
      <div className="m-5">
        <h2>Payment Page</h2>
        <button className="btn btn-primary" onClick={createOrder}>
          Create Razorpay Order
        </button>
        {orderId && <p>Order ID: {orderId}</p>}
      </div>

      <div className="m-5">
        <h2>{product.name}</h2>
        <p>Price: ₹{product.price}</p>
        <button className="btn btn-success" onClick={openRazorpay}>
          Pay Now
        </button>
      </div>
    </>
  );
};

export default RazorPayTest;
