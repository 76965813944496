// src/Cart.js
import React, { useEffect, useState } from "react";
import "../css/cart.css";
import { services } from "../services/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Cart = ({
  cartObj,
  finalCartObj,
  onDelete,
  onQuantityChange,
  currentUser,
  isLoggedIn,
  setNewUser,
  onAddFavorites,
}) => {
  console.log(cartObj, "cartObj cart.js");
  console.log(currentUser, "currentUser cart.js");
  console.log(finalCartObj, "finalCartObj cart.js");
  console.log(isLoggedIn, "isLoggedIn cart.js");

  const myLogo = require("../assets/images/icons/GlobalLogo-removebg-preview.png");

  const [cart, setCart] = useState();
  const [finalCart, setFinalCart] = useState([]);

  // Update cart state when cartObj changes
  useEffect(() => {
    if (cartObj) {
      setCart(cartObj);
      setFinalCart(finalCartObj);
    }
  }, [cartObj, finalCartObj]); // Only run when cartObj changes

  const handleDeleteCart = async (item) => {
    onDelete(item); // Call the delete function from StepperCheckout.js with the item ID
  };

  const addfavoriteItems = async (cartItem) => {
    console.log(cartItem, "cartItem");
    onAddFavorites(cartItem);
  };

  const handleQuantityChange = (e, item) => {
    const selectedQuantity = Number(e.target.value); // Convert the selected value to a number
    onQuantityChange(item, selectedQuantity);
  };

  // Update cart state when cartObj changes
  useEffect(() => {
    console.log(cart, "cart cart.js");
    console.log(finalCart, "finalCart cart.js");
  }, [cart, finalCart]); // Only run when cartObj changes

  return (
    <>
      {/* <div style={containerStyle}>
        <div style={contentStyle}>
          <h5 style={headingStyle}>Your Cart</h5>
          <p style={paragraphStyle}>You have no items in your cart.</p>
        </div>
      </div>
      <MainFooter /> */}
      <div className="cart-main container">
        {finalCart && finalCart.items > 0 && finalCart.totalPrice >= 0 && (
          <>
            <div className="free-delivery-main">
              <div className="free-delivery-icon">
                <i className="bi bi-truck truck-icon"></i>
              </div>
              {isLoggedIn && (
                <>
                  <div className="free-delivery-title">
                    {`Hi ${currentUser?.firstName}`} You get free delivery on
                    this order
                  </div>
                </>
              )}
              {!isLoggedIn && (
                <>
                  <div className="free-delivery-title">
                    Hi You get free delivery on this order
                  </div>
                </>
              )}
            </div>
          </>
        )}

        <div className="row price-cards-wrapper">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div
              className={`cart-main-wrapper ${
                finalCart && finalCart.items > 0
                  ? "cart-main-wrapper"
                  : "cart-main-wrapper-end"
              }`}
            >
              {cart && cart.length > 0 && (
                <>
                  {cart.map((item, index) => (
                    <>
                      <div className="cart-card">
                        <div className="cart-col-one">
                          <div className="cart-image-section">
                            <img
                              className="cart-image"
                              src={`${item.main_image?.replace(/\\/g, "/")}`}
                            />
                            <div className="qty-select">
                              <select
                                value={item?.quantity}
                                onChange={(event) => {
                                  handleQuantityChange(event, item);
                                }}
                                className="form-select"
                              >
                                {[...Array(10).keys()].map((num) => (
                                  <option key={num + 1} value={num + 1}>
                                    Qty: {num + 1}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="cart-content">
                            <div className="cartpname">{item?.name}</div>
                            <div className="cart-pricing-section pricing-section">
                              <span className="price-style cart-price">
                                ₹{item?.price}
                              </span>
                              <div className="pricing-section">
                                <span className="previous-price ">
                                  ₹ {item?.before_price}
                                </span>
                              </div>
                            </div>
                            <div>
                              <span className="cart-save-name ">You Save </span>
                              <span className="cart-save-price">
                                ₹{item?.savedPrice}{" "}
                              </span>
                            </div>

                            <div className="des-product">
                              {(item?.type == "clothing" ||
                                item?.type == "watches") && (
                                <div className="des-product-color">
                                  <span>Color :</span>
                                  <span className="des-value">
                                    {item?.color}
                                  </span>
                                </div>
                              )}
                              {item?.type != "watches" && (
                                <>
                                  <div className="des-product-size">
                                    <span>Size :</span>
                                    <span className="des-value">
                                      {" "}
                                      {item?.size}
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="cart-col-two">
                          <div
                            className="cart-col-sub delete-cart "
                            onClick={() => handleDeleteCart(item)}
                          >
                            <i className="bi bi-trash cart-col-sub-icon"></i>
                          </div>
                          <span className="separator"></span>
                          <div
                            className=" cart-col-sub whistlist-btn cart-col-sub-text "
                            onClick={() => addfavoriteItems(item)}
                          >
                            Add to favorites
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              )}

              {finalCart && finalCart.items > 0 && finalCart.totalPrice >= 0 ? (
                <div className="price-details-card">
                  <div className="card-title">
                    Price details ({finalCart?.items} Items)
                  </div>
                  <div className="price-spliter-section">
                    <div className="price-splitter">
                      <div className="spliter-title">
                        Total MRP (Inc. of Taxes)
                      </div>
                      <div className="spliter-value">
                        ₹{finalCart?.totalMRP}
                      </div>
                    </div>
                    <div className="price-splitter">
                      <div className="spliter-title">Discount</div>
                      <div className="spliter-value">
                        - ₹{finalCart?.totalDiscount}
                      </div>
                    </div>
                    <div className="price-splitter">
                      <div className="spliter-title shipping-title">
                        Shipping
                      </div>
                      <div className="spliter-value ">
                        <span className="previous-price">
                          {/* ₹{finalCart?.shipping} */}
                          ₹95
                        </span>
                        <span className="shipping-value shipping-title">
                          Free
                        </span>{" "}
                      </div>
                    </div>

                    <div className="price-splitter">
                      <div className="spliter-title">Cart Total</div>
                      <div className="spliter-value">
                        ₹{finalCart?.totalMRP - finalCart?.totalDiscount}
                      </div>
                    </div>

                    {finalCart?.deliveryCharge > 0 && (
                      <>
                        <div className="price-splitter">
                          <div className="spliter-title">Cod Charges</div>
                          <div className="spliter-value">
                            ₹{finalCart?.deliveryCharge}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className=" total-splitter">
                    <div className="total-title">Total Amount</div>
                    <div className="total-title">₹{finalCart?.totalPrice}</div>
                  </div>
                </div>
              ) : (
                <div className="invalid-cart">
                  {/* Render content for an invalid cart */}
                  {/* <img
                    src={myLogo}
                    alt="Global India Shop"
                    className="cart-logo"
                  /> */}
                  <i class="bi bi-cart-plus-fill empty-cart"></i>
                  <h2>No Items in Cart</h2>
                  <p>Please add items to your cart to proceed.</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <TrustCards from="cart-page" /> */}

        {/* <div className="offer-card">
          <div className="offer-title">
            <div className="offer-icon">
              <img src={offer} alt="" />
            </div>
            <div className="offer-text">Offer & Benefits</div>
          </div>
          <div className="pincode-box">
            <input
              type="text"
              placeholder="Enter Coupon Code"
              className="form-control pincode-input"
            />
            <button className="btn pincode-btn">Apply</button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Cart;
