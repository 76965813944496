// src/Messages.js
import React from "react";

const Messages = () => {
  // Define inline styles
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background:
      "url(https://images.unsplash.com/photo-1526481280304-8d67f491a302) no-repeat center center fixed",
    backgroundSize: "cover",
    color: "#fff",
    textAlign: "center",
    padding: "2rem",
  };

  const contentStyle = {
    background: "#ff7070", // Semi-transparent background
    padding: "2rem",
    borderRadius: "8px",
    maxWidth: "600px",
  };

  const headingStyle = {
    fontSize: "18px",
    marginBottom: "1rem",
  };

  const paragraphStyle = {
    fontSize: "16px",
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h5 style={headingStyle}>Your Orders and Notifications</h5>
        <p style={paragraphStyle}>
          Your orders and notifications will appear here.
        </p>
      </div>
    </div>
  );
};

export default Messages;
